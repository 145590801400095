import React, { useEffect } from "react";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";
import { useDispatch, useSelector } from "react-redux";
import { getAllCurrencies } from "./../../../../redux/currency/currencyAction";

export default function PersonalDetails({
  handleChange,
  data,
  phone,
  setPhone,
  handleSubmit,
  submitted,
  Location,
  locationId,
  locationList,
  setLocationList,
  setLocationId,
  handleRegion,
  handleNext,
}) {
  const dispatch = useDispatch();
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);
  const { allCurrencies } = useSelector((state) => state.currency);
  const {
    city,
    state,
    country,
    zip,
    address,
    name,
    currency,
    base_url,
    contractor,
    token
  } = data;

  useEffect(() => {
    dispatch(getAllCurrencies());
    // eslint-disable-next-line
  }, []);
  var currencies = [];
  for (const alphabetic_code in allCurrencies) {
    currencies.push(allCurrencies[alphabetic_code]);
  }

  function onKeyDown(event) {
    return (
      (event.charCode >= 65 && event.charCode <= 90) ||
      (event.charCode >= 97 && event.charCode <= 122)
    );
  }
  return (
    <>
      <div className=" mx-auto bg-white sm:w-[630px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200">
        <div className="border-b-2 py-4 mb-6">
          <p className=" text-base font-medium text-gray-800 flex justify-center ">
            Create Shipper
          </p>
        </div>
        <form name="form" id="shippers-form" onSubmit={(e) => handleSubmit(e)}>
          <div className=" sm:px-10">
            <div>
              <Input.Label
                title={"Shipper Name*"}
                htmlFor={"name"}
                name={"name"}
                value={name}
                placeholder={"Enter a name"}
                onChange={handleChange}
                onKeyDown={(e) => onKeyDown(e)}
              />
              {submitted && !name && (
                <div className="text-primary text-xs ">
                  Shipper name is required
                </div>
              )}
            </div>
            <div>
              <Input.Phone
                title={"Shipper Phone Number*"}
                htmlFor={"phone"}
                name={"phone"}
                value={phone}
                onChange={setPhone}
              />
              {submitted && !phone && (
                <div className="text-primary text-xs ">
                  Phone number is required
                </div>
              )}
            </div>
            <div>
              <Input.Label
                title={"Shipper Address*"}
                htmlFor={"address"}
                name={"address"}
                value={address}
                onChange={handleChange}
                placeholder={"Enter an Address"}
                onKeyDown={(e) => onKeyDown(e)}
              />
              {submitted && !address && (
                <div className="text-primary text-xs ">Address is required</div>
              )}
            </div>
            <Input.Select
              title={"Currency*"}
              htmlFor={"currency"}
              name={"currency"}
              value={currency}
              onChange={handleChange}
            >
              <option>{currency ? currency : "Select Currency"}</option>
              {currencies
                .sort((a, b) =>
                  a.alphabetic_code.localeCompare(b.alphabetic_code)
                )
                .map((currency, i) => (
                  <option key={i} value={currency.alphabetic_code}>
                    {currency.alphabetic_code}
                  </option>
                ))}
            </Input.Select>
            {submitted && !currency && (
              <div className="text-primary text-xs ">currency is required</div>
            )}
            <Location
              country_id={locationId.country_id}
              state_id={locationId.state_id}
              city_id={locationId.city_id}
              countryList={(value) => {
                setLocationList({ ...locationList, countryList: value });
              }}
              stateList={(value) => {
                setLocationList({ ...locationList, stateList: value });
              }}
              cityList={(value) => {
                setLocationList({ ...locationList, cityList: value });
              }}
            />
            <div
              className={
                "mt-4" + (submitted && country ? " border-red-500" : "")
              }
            >
              <label
                htmlFor="country"
                className="block text-sm font-medium text-gray-700"
              >
                Country*
              </label>
              <select
                name="country"
                value={country}
                className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFFFFF]  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    country_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{country ? country : "Select Country"}</option>
                {locationList.countryList &&
                  locationList.countryList.length > 0 &&
                  locationList.countryList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>

              {submitted && !country && (
                <div className="text-xs text-red-500">Country is required</div>
              )}
            </div>
            <div
              className={"mt-4" + (submitted && state ? " border-red-500" : "")}
            >
              <label
                htmlFor="state"
                className="block text-sm font-medium text-gray-700"
              >
                State
              </label>
              <select
                name="state"
                value={state}
                className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFFFFF]  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    state_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{state ? state : "Select state"}</option>

                {locationList.stateList &&
                  locationList.stateList.length > 0 &&
                  locationList.stateList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !state && (
                <div className="text-xs text-red-500">State is required.</div>
              )}
            </div>
            <div
              className={"mt-4 " + (submitted && city ? " border-red-500" : "")}
            >
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                City*
              </label>
              <select
                name="city"
                value={city}
                className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFF] focus:outline-none  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    city_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{city ? city : "Select a city"}</option>
                {locationList.cityList &&
                  locationList.cityList.length > 0 &&
                  locationList.cityList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !city && (
                <div className="text-xs text-red-500">City is required</div>
              )}
            </div>
            <div className="mt-4">
              <Input.Label
                title={"Zip*"}
                htmlFor={"zip"}
                name={"zip"}
                value={zip}
                onChange={handleChange}
                placeholder={"Enter Zip code"}
              />
              {submitted && !zip && (
                <div className="text-primary text-xs ">zip is required</div>
              )}
            </div>
            <div className="mt-4">
              <Input.Label
                title={"Contractor*"}
                htmlFor={"contractor"}
                name={"contractor"}
                value={contractor}
                onChange={handleChange}
                placeholder={"Enter contractor"}
              />
              {submitted && !contractor && (
                <div className="text-primary text-xs ">
                  Contractor is required
                </div>
              )}
            </div>
            <div className="mb-10">
              <Input.Label
                title={"Base Url*"}
                htmlFor={"base_url"}
                name={"base_url"}
                value={base_url}
                onChange={handleChange}
                placeholder={"Enter base_url"}
              />
              {submitted && !base_url && (
                <div className="text-primary text-xs ">
                  Base url is required
                </div>
              )}
            </div>
            <div className="mb-10">
              <Input.Label
                title={"Token*"}
                htmlFor={"token"}
                name={"token"}
                value={token}
                onChange={handleChange}
                placeholder={"Enter token"}
              />
              {submitted && !token && (
                <div className="text-primary text-xs ">Token is required</div>
              )}
            </div>
          </div>
        </form>
      </div>
      <Button.Primary
        onClick={handleNext}
        title={"Next"}
        className={" w-16 sm:mt-10 mx-auto rounded-sm flex justify-center "}
      />
    </>
  );
}
