import React from "react";
import { RiLoader5Fill } from "react-icons/ri";

const Button = ({ children }) => {
  return (
    <div className="Button" data-test="buttonComponent">
      {" "}
      {children}
    </div>
  );
};

Button.Base = ({
  title,
  style,
  className,
  disabled,
  loading,
  type,
  form,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      form={form}
      style={style}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={
        "p-2 rounded-md text-center text-white text-md font-bold whitespace-nowrap " +
        className
      }
    >
      {title ?? "Title"}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
};

Button.Primary = ({
  title,
  style,
  className = "",
  disabled,
  loading,
  type,
  form,
  onClick = () => {},
}) => {
  return (
    <button
      type={type}
      form={form}
      style={style}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={
        disabled || loading
          ? `bg-gray-400 p-2 rounded-md text-center text-white whitespace-nowrap cursor-not-allowed ${className} `
          : `bg-[#CE2600] px-3 py-2 rounded-md text-base text-center text-white whitespace-nowrap  ${className} `
      }
    >
      {title ?? "Title"}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
};

Button.Secondary = ({
  title,
  style,
  className,
  disabled,
  loading,
  onClick = () => {},
}) => {
  return (
    <button
      type={"button"}
      style={style}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={
        disabled || loading
          ? `bg-gray-400 p-2 rounded-md text-center text-white whitespace-nowrap cursor-not-allowed ${className} `
          : `bg-white px-3 py-2 rounded-md text-base text-center font-medium border border-primary text-primary whitespace-nowrap  ${className}`
      }
    >
      {title ?? "Title"}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
};
Button.Transparent = ({
  title,
  style,
  className,
  disabled,
  loading,
  onClick = () => {},
}) => {
  return (
    <button
      type={"button"}
      style={style}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={
        disabled || loading
          ? `bg-gray-400 p-2 rounded-md text-center text-white whitespace-nowrap cursor-not-allowed ${className} `
          : ` bg-inherit px-3 py-2 rounded-md text-md text-center font-medium border border-primary text-primary whitespace-nowrap  ${className}`
      }
    >
      {title ?? "Title"}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
};

Button.Green = ({
  title,
  style,
  className,
  loading,
  disabled,
  onClick = () => {},
}) => {
  return (
    <button
      type={"button"}
      style={style}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={
        "bg-green-500 p-2 rounded-md text-center text-white whitespace-nowrap " +
        className
      }
    >
      {title ?? "Title"}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
};

Button.Red = ({
  title,
  style,
  className,
  loading,
  disabled,
  onClick = () => {},
}) => {
  return (
    <button
      type={"button"}
      style={style}
      onClick={() => onClick()}
      disabled={disabled || loading}
      className={
        "bg-red-500 p-2 rounded-md text-center text-white whitespace-nowrap " +
        className
      }
    >
      {title ?? "Title"}
      {loading && <RiLoader5Fill size={24} className="animate-spin ml-4" />}
    </button>
  );
};

export default Button;
