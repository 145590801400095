import { initializeApp } from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";

const firebaseUrl = process.env.REACT_APP_FIREBASE_URL;
const firebaseKey = process.env.REACT_APP_FIREBASE_SERVER_KEY;

// Your Firebase config here
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: firebaseKey,
  authDomain: "ogwugo-food-318602.firebaseapp.com",
  databaseURL: firebaseUrl,
  projectId: "ogwugo-food-318602",
  storageBucket: "ogwugo-food-318602.appspot.com",
  messagingSenderId: "769733068122",
  appId: "1:769733068122:web:078d9a24249c1ddf751f63",
  measurementId: "G-D6H400TXV9",
};

//Initialize Firebase
export const fireApp = initializeApp(firebaseConfig);

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(fireApp);
    }
    console.log("Firebase not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();
