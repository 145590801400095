import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { couponService } from "./../../services/coupon.service";
import couponType from "./couponTypes";

/**
 * GetAllCoupons
 *
 */
export const getAllCoupons = (page) => async (dispatch) => {
  try {
    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: true,
    });
    const response = await couponService.getAllCoupons(page);
    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: false,
    });
    dispatch({
      type: couponType["GET_ALL_COUPON"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * StoreCoupons
 * @param {object} payLoad{ name:"gaming",types: skilled}
 * @returns {void}
 * */
export const createCoupon = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: true,
    });
    const response = await couponService.storeCoupon({
      ...payLoad,
    });
    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: false,
    });
    dispatch({
      type: couponType["STORE_COUPON"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Delete Coupon
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const deleteCoupon = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: couponType["DELETING"],
      payLoad: true,
    });
    const response = await couponService.deleteCoupon({
      id: payLoad,
    });
    dispatch({
      type: couponType["DELETING"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: couponType["DELETING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Expired Coupon
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const expiredCoupon = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: couponType["DELETING"],
      payLoad: true,
    });
    const response = await couponService.expiredCoupon({
      id: payLoad,
    });
    dispatch({
      type: couponType["DELETING"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: couponType["DELETING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Edit Coupon
 * @param {object} payLoad {id:"22"}
 * @returns {void}
 * */
export const editCoupon = (payLoad) => async (dispatch) => {
  try {
    const response = await couponService.editCoupon({
      ...payLoad,
    });

    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: false,
    });
    dispatch({
      type: couponType["EDIT_COUPON"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
/**
 * Edit Coupon
 * @param {object} payLoad {search:"ogwugo"}
 * @returns {void}
 * */
export const searchCoupons = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: couponType["LOADING_SEARCH"],
      payLoad: true,
    });
    const response = await couponService.searchCoupon({
      ...payLoad,
    });

    dispatch({
      type: couponType["LOADING_SEARCH"],
      payLoad: false,
    });
    dispatch({
      type: couponType["SEARCH_COUPON"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: couponType["LOADING_SEARCH"],
      payLoad: false,
    });
  }
};
/**
 * Edit Coupon
 * @param {object} payLoad {company_id :"7b3e58e0-b68c-498d-b8d5-82198ef6d481"}
 * @returns {void}
 * */
export const filterCoupons = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({
      type: couponType["FILTERING"],
      payLoad: true,
    });
    const response = await couponService.filterCoupon({
      ...payLoad,
      page,
    });
    dispatch({
      type: couponType["FILTERING"],
      payLoad: false,
    });
    dispatch({
      type: couponType["FILTER_COUPON"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: couponType["FILTERING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
export const filterCouponsNoPage = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: couponType["FILTERING"],
      payLoad: true,
    });
    const response = await couponService.filterCouponNoPage({
      ...payLoad,
    });
    dispatch({
      type: couponType["FILTERING"],
      payLoad: false,
    });
    dispatch({
      type: couponType["FILTER_COUPON_NOPAGE"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: couponType["FILTERING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
/**
 * Show Coupon
 * @param {object} payLoad {properties:1}
 * */
export const showCoupon = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: true,
    });
    const response = await couponService.showACoupon(payLoad);

    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: false,
    });
    dispatch({
      type: couponType["SHOW_COUPON"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};
/**
 * Show my Coupon
 * @param {object} payLoad {properties:1}
 * */
export const showMyCoupon = () => async (dispatch) => {
  try {
    const response = await couponService.showMyCoupon();
    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: false,
    });
    dispatch({
      type: couponType["SHOW_MY_COUPON"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: couponType["LOADING_COUPON"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
