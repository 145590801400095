import React from "react";
import Logo from "../../assets/images/icons/ogwugo-white-logo.svg";
import AppStore from "../../assets/images/icons/app-store.svg";
import PlayStore from "../../assets/images/icons/play-store.svg";
import { Link } from "react-router-dom";
//icons
import { ReactComponent as LinkedIn } from "../../assets/images/icons/linkedin.svg";
import { ReactComponent as Instagram } from "../../assets/images/icons/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/images/icons/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/images/icons/twitter.svg";

const userNavigation = [
  { name: "Restaurants", href: "/user/restaurants" },
  { name: "Stores", href: "/user/stores" },
  { name: "Packages", href: "/user/food-packages" },
];
const companyNavigation = [{ name: "Privacy Policy", href: "/privacy-policy" }];

export default function FooterCommon() {
  // const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  return (
    <div className="bg-[#080A0C]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-6 mt-24">
        <div className="relative grid justify-between pt-16 pb-8 xl:grid xl:grid-cols-12 lg:gap-8">
          <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
            <div className="flex-shrink-0 flex items-start">
              <a href="/">
                <img className="block h-8 w-auto" src={Logo} alt="Ogwugo" />
              </a>
            </div>
          </div>
          <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-10">
            <div className="flex flex-col sm:flex-row sm:justify-between gap-8 lg:gap-24">
              <div className="flex flex-col sm:flex-row gap-8 lg:gap-24">
                <div>
                  <h5 className="font-bold font-md mb-5 text-white capitalize">
                    Download the app
                  </h5>
                  <div className="flex gap-6 sm:block">
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href="https://apps.apple.com/ng/app/ogwugo/id1572959997"
                    >
                      <img
                        src={AppStore}
                        alt="app-store"
                        className="mb-3 border rounded-lg"
                      />
                    </a>
                    <a
                      target={"_blank"}
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.ogwugo.ogwugofood&hl=en&gl=US"
                    >
                      <img
                        src={PlayStore}
                        alt="app-store"
                        className="border rounded-lg"
                      />
                    </a>
                  </div>
                </div>
                <div>
                  <h5 className="font-bold font-md mb-5 text-white">
                    Let’s Do This Together
                  </h5>
                  {userNavigation.map((nav) => (
                    <Link
                      to={nav.href}
                      key={nav.name}
                      className="block mb-3 text-[#D8D8D8]"
                    >
                      {nav.name}
                    </Link>
                  ))}
                </div>
                <div>
                  <h5 className="font-bold font-md mb-5 text-white">
                    Ogwugo Links
                  </h5>
                  {companyNavigation.map((nav) => (
                    <Link
                      to={nav.href}
                      key={nav.name}
                      className="block mb-3 text-[#D8D8D8]"
                    >
                      {nav.name}
                    </Link>
                  ))}
                </div>
              </div>

              <div
                className="px-6 py-4 rounded-lg"
                style={{
                  background:
                    "radial-gradient(95.53% 88.78% at 50% 46.15%, #E8E3E3 0%, #E7D5D0 100%)",
                  width: "254px",
                  paddingTop: "52px",
                }}
              >
                <a
                  href="https://api.whatsapp.com/message/H2DWHXYKIF5KI1?autoload=1&app_absent=0"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <h5 className="font-bold text-xl mb-5 text-[#333333]">
                    Want Ogwugo to pick or deliver a waybill for you?
                  </h5>
                  <span className="font-bold text-[#333333]">Click Here</span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <hr className="my-2 border-[#404040]" />

        <div className="py-4 sm:flex sm:justify-between">
          <p className="text-[#D8D8D8] mb-4 sm:mb-0">
            ©️ Ogwugo Limited 2017–{currentYear}
          </p>
          <div className="flex gap-4">
            <a
              href="https://www.linkedin.com/company/ogwugo/"
              target={"_blank"}
              rel="noreferrer"
              className="h-9 w-9 border border-[#404040] rounded-full flex items-center justify-center"
            >
              <LinkedIn style={{ fill: "#D8D8D8" }} />
            </a>

            <a
              href="https://www.instagram.com/ogwugofood"
              target={"_blank"}
              rel="noreferrer"
              className="h-9 w-9 border border-[#404040] rounded-full flex items-center justify-center"
            >
              <Instagram style={{ fill: "#D8D8D8" }} />
            </a>

            <a
              href="https://twitter.com/ogwugofood"
              target={"_blank"}
              rel="noreferrer"
              className="h-9 w-9 border border-[#404040] rounded-full flex items-center justify-center"
            >
              <Twitter style={{ fill: "#D8D8D8" }} />
            </a>

            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://web.facebook.com/ogwugofood"
              className="h-9 w-9 border border-[#404040] rounded-full flex items-center justify-center"
            >
              <Facebook style={{ fill: "#D8D8D8" }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
