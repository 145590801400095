import React, { useMemo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/buttons/buttons";
import Card from "../../../../components/cards/admin/cards";
import Table from "../../../../components/tables/tableCols1";
import { Pagination } from "../../../../components/pagination/pagination";
import { getSingleStore } from "../../../../redux/stores/storeActions";
import moment from "moment";

// sample data array looks like this
const data = [
  {
    show: {
      name: "001",
      type: "Bronx Pharmacy",
      language: "090989796948",
      approval: "Approved",
      status: "Pending",
      location: "Enugu",
    },
  },
  {
    show: {
      name: "002",
      type: "Impala Plaza",
      language: "090989796948",
      approval: "Approved",
      status: "Pending",
      location: "Enugu",
    },
  },
  {
    show: {
      name: "003",
      type: "Bronx Pharmacy",
      language: "090989796948",
      approval: "Approved",
      status: "Pending",
      location: "Enugu",
    },
  },
  {
    show: {
      name: "004",
      type: "Impala Plaza",
      language: "090989796948",
      approval: "Approved",
      status: "Pending",
      location: "Enugu",
    },
  },

  {
    show: {
      name: "004",
      type: "Impala Plaza",
      language: "090989796948",
      approval: "Approved",
      status: "Pending",
      location: "Enugu",
    },
  },
  {
    show: {
      name: "005",
      type: "Bronx Pharmacy",
      language: "090989796948",
      approval: "Approved",
      status: "Pending",
      location: "Enugu",
    },
  },
  {
    show: {
      name: "006",
      type: "Impala Plaza",
      language: "090989796948",
      approval: "Approved",
      status: "Pending",
      location: "Enugu",
    },
  },

  {
    show: {
      name: "005",
      type: "Bronx Pharmacy",
      language: "090989796948",
      approval: "Approved",
      status: "Pending",
      location: "Enugu",
    },
  },
  {
    show: {
      name: "006",
      type: "Impala Plaza",
      language: "090989796948",
      approval: "Approved",
      status: "Pending",
      location: "Enugu",
    },
  },
];

export default function StoreReportDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { singleStore } = useSelector((state) => state.store);

  useEffect(() => {
    dispatch(getSingleStore(id));
    // eslint-disable-next-line
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.name",
          },
          {
            Header: "Store Name",
            accessor: "show.type",
          },
          {
            Header: "Phone Number",
            accessor: "show.language",
          },
          {
            Header: "Company Approval",
            accessor: "show.approval",
          },
          {
            Header: "Location",
            accessor: "show.location",
          },
          {
            Header: "Status",
            accessor: "show.status",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="singleStore-page">
      <div className="mb-10">
        <h1 className="text-3xl font-semibold text-gray-900">
          Store: {singleStore.store_name}
        </h1>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">{singleStore.store_name}</h3>
          <Button.Primary
            title={"Check Stats"}
            onClick={() => navigate("/admin/business-report/statistics")}
          />
        </div>

        <div className="grid grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Store ID</label>
            <p className="text-[#151515] text-sm">{singleStore?.id}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm">{singleStore?.store_email}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] text-sm">{singleStore?.store_phone}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Flag Count</label>
            <p className="text-[#151515] text-sm">{singleStore?.flag}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Approve/Unapprove
            </label>
            {singleStore?.management_approved === false ? (
              <p className="text-[#151515] text-sm">Unapproved</p>
            ) : (
              <p className="text-[#151515] text-sm">Approved</p>
            )}
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
            <p className="text-[#151515] text-sm">
              {moment(singleStore?.created_at).format("DD, MMM YYYY, HH:MM A")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
            <p className="text-[#151515] text-sm">
              {singleStore?.management_approved ? "Approved" : "Unapproved"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
            <p className="text-[#151515] text-sm">
              {singleStore?.store_country}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">State</label>
            <p className="text-[#151515] text-sm">{singleStore?.store_state}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">City</label>
            <p className="text-[#151515] text-sm"> {singleStore?.store_city}</p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Store Owner Details</h3>
        </div>

        <div className="grid grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
            <p className="text-[#151515] text-sm">XXAABZCD002</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm">XXAABZCD002</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] text-sm">XXAABZCD002</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
            <p className="text-[#151515] text-sm">XXAABZCD002</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Approve/Unapprove
            </label>
            <p className="text-[#151515] text-sm">XXAABZCD002</p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Orders History</h3>
        </div>

        <div className="grid grid-cols-6 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Total Orders </label>
            <p className="text-[#151515] text-sm">0</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Accepted Orders
            </label>
            <p className="text-[#151515] text-sm">0</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Completed Orders
            </label>
            <p className="text-[#151515] text-sm">0</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Pending Orders
            </label>
            <p className="text-[#151515] text-sm">0</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Cancelled Orders
            </label>
            <p className="text-[#151515] text-sm">0</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Rejected Orders
            </label>
            <p className="text-[#151515] text-sm">0</p>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <Card.TableFilter results={80}>
          <Button.Secondary title={"Download CSV"} />
        </Card.TableFilter>

        <div className="border-b border-[#E4E4F3]"></div>

        <div className="bg-white rounded-md py-4 px-8">
          <Table columns={columns} data={data} className="w-full" />
        </div>
        <Pagination />
      </div>
    </div>
  );
}
