import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../buttons/buttons";
import { Beat } from "../../../plugins/spinners.plugin";
import {
  storeFeedback,
  filterFeedback,
} from "../../../redux/feedback/feedbackActions";

export default function StoreStoreFeedback({
  closeModal,
  store_order_id,
  setIsNewFeedback,
}) {
  const dispatch = useDispatch();
  const { storingFeedback } = useSelector((state) => state.feedback);
  const [data, setData] = useState({
    store_order_id: store_order_id,
    description: "",
  });

  const { description } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await dispatch(storeFeedback(data));
      if (response) {
        dispatch(
          filterFeedback({
            store_order_id: store_order_id,
            pagination: 0,
          })
        );
        setIsNewFeedback(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <React.Fragment>
      <div className="border-b-2 py-2 border-[#EAEAEA]">
        <h3 className="text-left font-medium text-lg">Feedback Message</h3>
      </div>

      <div className="my-4">
        <p className="text-[18px]">
          Your feedback is valuable to{" "}
          <span className="text-primary">Ogwugo.</span> Please share more about
          your experience.
        </p>
      </div>

      <div className="flex justify-between items-center py-2">
        <textarea
          name="description"
          className="w-full"
          onChange={handleChange}
        />
      </div>

      <Button.Primary
        title={storingFeedback ? <Beat /> : "Submit"}
        type="button"
        disabled={description.length < 6 || storingFeedback}
        className="w-full py-3 mt-6"
        onClick={handleSubmit}
      />
      <Button.Secondary
        title={"Cancel"}
        type="button"
        className="w-full py-3 mt-6"
        onClick={closeModal}
      />
    </React.Fragment>
  );
}
