import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RestaurantLayout from "../../../../layouts/RestaurantLayout";
import SelectedMeal from "../../../../components/singleList/selectedMeal";
import LeftSide from "./LeftMeals";
import { RightColumn } from "../../../../layouts/RestaurantLayout";
import { getDaysArray } from "../../../../components/helpers";
import { cleanInput } from "../../../../helpers/cleanInput";
import Empty from "../../../../assets/images/icons/EmptyState.svg";
import {
  editCart,
  showMyCartNoProperties,
} from "../../../../redux/cart/cartAction";
import {
  getUserFromCookies,
  isAuthenticatedUser,
} from "../../../../utils/Auth";
import { storePublicCartProducts } from "../../../../redux/cart/cartAction";

export default function ChooseMeals() {
  /*eslint-disable eqeqeq*/
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = getUserFromCookies();
  const isAuth = isAuthenticatedUser();

  const { myCartNoProperties } = useSelector((state) => state.cart);
  const { singlePackage } = useSelector((state) => state.publicPackages);
  const { settings } = useSelector((state) => state.application);

  const [selectedMeal, setSelectedMeal] = useState([]);
  const [count, setCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [subscriptionDays, setSubscriptionDays] = useState({
    monday: {},
    tuesday: {},
    wednesday: {},
    thursday: {},
    friday: {},
    saturday: {},
    sunday: {},
  });

  const [startDate, setStartDate] = useState(
    new Date(location.state.startDate)
  );
  let endDate = new Date(location.state.endDate);

  //get the cart details without properties for the sake of updating cart
  useEffect(() => {
    if (isAuth) {
      dispatch(showMyCartNoProperties());
    }
    // eslint-disable-next-line
  }, [isAuth]);

  const numberOfDays = () => {
    const date1 = new Date(location.state.startDate);
    const date2 = new Date(location.state.endDate);
    const tolerance = 1; // 1 millisecond tolerance
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // Check if the start and end dates are the same
    if (diffTime <= tolerance) {
      return 1; // Dates are effectively the same
    }

    // Cap the result at a maximum of seven days
    return Math.min(diffDays + 1, 7);
  };

  // for converting date to local date string
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  endDate = endDate.toLocaleDateString("en-us", options);

  //change button onclick
  function changeMeal(chosenDate) {
    let currentMeal = selectedMeal.find((a) => {
      if (a.date.getTime() === chosenDate.getTime()) {
        return a;
      }
      return null;
    });
    setStartDate(new Date(currentMeal.date));
  }

  useEffect(() => {
    const allDates = getDaysArray(
      new Date(location.state.startDate),
      new Date(location.state.endDate)
    );
    allDates.forEach((a, index) => {
      allDates[index] = { date: a, display: false, skipped: false };
    });

    setSelectedMeal(allDates);
  }, [location.state]);
  //to scroll up on each day render

  useEffect(() => {
    window.scrollTo({ top: 300, left: 0, behavior: "smooth" });
  }, [count]);

  const handleSubmit = async () => {
    const localCart = JSON.parse(localStorage.getItem("ogwugo-cart"));
    let start_date = new Date(location.state.startDate);
    let previous_packages = myCartNoProperties?.packages ?? [];
    cleanInput(subscriptionDays);
    let data = {
      user_id: user?.id,
      packages: [
        ...previous_packages,
        {
          package_id: singlePackage?.id,
          package_type: "food",
          package_delivery_period: {
            start_time: location?.state?.package_delivery_period?.start_time,
            end_time: location?.state?.package_delivery_period?.end_time,
          },
          subscription_start_date: moment(start_date).format("YYYY-MM-DD"),
          subscription_end_date: moment(endDate).format("YYYY-MM-DD"),
          subscription_days: subscriptionDays,
          delete_id: Math.random(),
        },
      ],
    };

    if (isAuth) {
      try {
        const response = await dispatch(editCart(data));
        if (response?.status === "success") {
          navigate("/cart");
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      //do this when users are not logged in and have items in local packages
      if (localCart) {
        if (
          settings?.multiple_outlet_checkout_status === "unavailable" &&
          localCart?.packages?.length > 0
        ) {
          toast.error("You cannot add items from multiple outlets into cart!", {
            position: toast.POSITION.TOP_RIGHT,
            theme: "colored",
          });
          return;
        }
        //if there are already packages, add to them else add for the first time
        let updateCart = localCart;
        if (localCart?.packages) {
          updateCart.packages = [
            ...localCart.packages,
            {
              package_id: singlePackage?.id,
              package_type: "food",
              package_delivery_period: {
                start_time:
                  location?.state?.package_delivery_period?.start_time,
                end_time: location?.state?.package_delivery_period?.end_time,
              },
              subscription_start_date: moment(start_date).format("YYYY-MM-DD"),
              subscription_end_date: moment(endDate).format("YYYY-MM-DD"),
              subscription_days: subscriptionDays,
              delete_id: Math.random(),
            },
          ];
        } else {
          updateCart.packages = [
            {
              package_id: singlePackage?.id,
              package_type: "food",
              package_delivery_period: {
                start_time:
                  location?.state?.package_delivery_period?.start_time,
                end_time: location?.state?.package_delivery_period?.end_time,
              },
              subscription_start_date: moment(start_date).format("YYYY-MM-DD"),
              subscription_end_date: moment(endDate).format("YYYY-MM-DD"),
              subscription_days: subscriptionDays,
              delete_id: Math.random(),
            },
          ];
        }
        cleanInput(updateCart);
        try {
          const response = await dispatch(storePublicCartProducts(updateCart));
          if (response) {
            localStorage.setItem(
              "ogwugo-cart-items",
              JSON.stringify(response?.data)
            );
            localStorage.setItem("ogwugo-cart", JSON.stringify(updateCart));
            navigate("/cart");
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        //do this when users are not logged in and don't have any items in local packages
        let updateCart = {
          packages: [
            {
              package_id: singlePackage?.id,
              package_type: "food",
              package_delivery_period: {
                start_time:
                  location?.state?.package_delivery_period?.start_time,
                end_time: location?.state?.package_delivery_period?.end_time,
              },
              subscription_start_date: moment(start_date).format("YYYY-MM-DD"),
              subscription_end_date: moment(endDate).format("YYYY-MM-DD"),
              subscription_days: subscriptionDays,
              delete_id: Math.random(),
            },
          ],
        };
        cleanInput(updateCart);
        try {
          const response = await dispatch(storePublicCartProducts(updateCart));
          if (response) {
            localStorage.setItem(
              "ogwugo-cart-items",
              JSON.stringify(response?.data)
            );
            localStorage.setItem("ogwugo-cart", JSON.stringify(updateCart));
            navigate("/cart");
          }
        } catch (error) {
          console.log("error", error);
        }
      }
    }
  };

  return (
    <RestaurantLayout restaurant={singlePackage}>
      {/* Left column */}
      <LeftSide
        startDate={startDate}
        handleSubmit={handleSubmit}
        count={count}
        setCount={setCount}
        counter={counter}
        setCounter={setCounter}
        subscriptionDays={subscriptionDays}
        setSubscriptionDays={setSubscriptionDays}
        setStartDate={setStartDate}
        selectedMeal={selectedMeal}
        setSelectedMeal={setSelectedMeal}
      />

      {/* Right column */}
      <RightColumn>
        <div className="flex justify-between items-center border-b-2 mb-3 ">
          <h2 className="text-2xl font-bold pb-3">Selected Meals</h2>
          <p className="text-primary-500">
            {counter}/{numberOfDays()} meals
          </p>
        </div>
        <p className="mb-4 text-[#676565] text-sm sm:text-base">
          You can select a maximum of {numberOfDays()} meals in this package.
        </p>
        {counter === 0 ? (
          <div className="  sm:mt-10 px-4 py-12 sm:w-[390px] sm:h-[500px]">
            <img src={Empty} alt="Empty Cart" className="mx-auto " />
            <p className="mt-6 text-xl font-bold text-center">
              We cant wait to see your selections
            </p>
            <p className=" text-lg text-gray-500 text-center max-w-[300px] ml-8 mt-4">
              Choose your meals to view, change or skip based on preference.
            </p>
          </div>
        ) : (
          selectedMeal.length > 0 &&
          selectedMeal?.map((meal, index) => (
            <SelectedMeal key={index} meal={meal} changeMeal={changeMeal} />
          ))
        )}
      </RightColumn>
    </RestaurantLayout>
  );
}
