import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../../components/skeleton/table";
import { Pagination } from "../../../../../components/pagination/pagination";
import { filterStores } from "../../../../../redux/stores/storeActions";

export default function Stores({ business_id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [data, setData] = useState([]);
  const { allStores, filtering, searching } = useSelector(
    (state) => state.store
  );

  const [payLoad] = useState({
    start_date: lastMonth,
    end_date: new Date(),
    business_id: business_id,
  });

  useEffect(() => {
    dispatch(filterStores(payLoad, page));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((store, index) => {
        let show = {
          id: index + 1,
          name: store.store_name,
          phone: store?.store_phone,
          approval: (
            <span>
              {store?.management_approved ? (
                <span className="text-[#00D220] font-bold">Approved</span>
              ) : (
                <span className="text-primary font-bold">Unapproved</span>
              )}
            </span>
          ),
          location: store?.store_city,
          email: store?.store_email,
          storeData: store,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allStores?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores]);

  function goToSinglePage(params) {
    let id = params?.show?.storeData?.id;
    navigate(`/admin/orders-report/store/${id}/shipped/1`, {
      state: { store: params?.show?.storeData },
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Store Name",
            accessor: "show.name",
          },
          {
            Header: "Phone Number",
            accessor: "show.phone",
          },
          {
            Header: "Company Approval",
            accessor: "show.approval",
          },
          {
            Header: "Location",
            accessor: "show.location",
          },
          {
            Header: "Email",
            accessor: "show.email",
          },
        ],
      },
    ],
    []
  );
  return (
    <div>
      <div className="bg-white rounded-md">
        {filtering || searching ? (
          <TableSkeleton />
        ) : (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        )}

        <Pagination data={allStores} route={"admin/business-report/stores"} />
      </div>
    </div>
  );
}
