import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { inviteService } from "./../../services/invite.service";
import inviteType from "./inviteTypes";

/**
 * GetAllInvites
 *
 */
export const getAllInvites = () => async (dispatch) => {
  try {
    dispatch({
      type: inviteType["LOADING_INVITE"],
      payLoad: true,
    });
    const response = await inviteService.getAllInvites();
    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    dispatch({
      type: inviteType[" GET_ALL_INVITES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * StoreInvites
 * @param {object} payLoad{ name:"gaming",types: skilled}
 * @returns {void}
 * */

let base_url = window.location.origin;
export const storeInvite = (payLoad, business) => async (dispatch) => {
  try {
    dispatch({
      type: inviteType["LOADING_INVITE"],
      payLoad: true,
    });
    const response = await inviteService.storeInvite({
      ...payLoad,
      business_id: business,
      invite_form_link: `${base_url}/auth/inviteRegisteration`,
    });
    dispatch({
      type: inviteType["LOADING_INVITE"],
      payLoad: false,
    });
    dispatch({
      type: inviteType["STORE_INVITE"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: inviteType["LOADING_INVITE"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Delete invite
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const deleteInvite = (payLoad) => async (dispatch) => {
  try {
    const response = await inviteService.deleteInvite({
      ...payLoad,
    });
    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    dispatch({
      type: inviteType["DELETE_INVITE"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Edit invite
 * @param {object} payLoad {id:"22"}
 * @returns {void}
 * */
export const editInvite = (payLoad) => async (dispatch) => {
  try {
    const response = await inviteService.editInvite({
      ...payLoad,
    });
    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    dispatch({
      type: inviteType["EDIT_INVITE"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};
/**
 * Edit invite
 * @param {object} payLoad {search:"ogwugo"}
 * @returns {void}
 * */
export const searchInvites = (payLoad) => async (dispatch) => {
  try {
    const response = await inviteService.searchInvites({
      ...payLoad,
    });
    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    dispatch({
      type: inviteType["SEARCH_INVITE"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};
/**
 * Edit invite
 * @param {object} payLoad {company_id :"7b3e58e0-b68c-498d-b8d5-82198ef6d481"}
 * @returns {void}
 * */
export const filterInvites = (payLoad) => async (dispatch) => {
  try {
    const response = await inviteService.filterInvites({
      ...payLoad,
    });
    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    dispatch({
      type: inviteType["FILTER_INVITES"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};
/**
 * Show invite
 * @param {object} payLoad {properties:1}
 * */
export const showInvite = (payLoad) => async (dispatch) => {
  try {
    const response = await inviteService.showInvite({
      ...payLoad,
    });

    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    dispatch({
      type: inviteType["SHOW_INVITES"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};
/**
 * Show my invite
 * @param {object} payLoad {properties:1}
 * */
export const showMyInvites = () => async (dispatch) => {
  try {
    const response = await inviteService.showMyInvites();
    dispatch({
      type: inviteType["LOADING_INVITE"],
      payLoad: false,
    });
    dispatch({
      type: inviteType["SHOW_MY_INVITE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: inviteType["LOADING_INVITE"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
/**
 * refuse invite
 * @param {object} payLoad  {id:"22"}
 * */
export const refuseInvite = (payLoad) => async (dispatch) => {
  try {
    const response = await inviteService.refuseInvite({
      ...payLoad,
    });

    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    dispatch({
      type: inviteType["REFUSE_INVITES"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: inviteType["LOADING_INVITES"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};
