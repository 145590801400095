import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { messaging } from "./../firebase/firebaseInit";
import { storeAPushNotification } from "../../redux/pushNotification/pushNotificationActions";

export default function FirebaseNotification() {
  const dispatch = useDispatch();
  const firebaseUrl = process.env.REACT_APP_FIREBASE_URL;
  const firebaseKey = process.env.REACT_APP_FIREBASE_SERVER_KEY;

  useEffect(() => {
    const requestPermission = async () => {
      try {
        await messaging.requestPermission();
        const token = await messaging.getToken();
        console.log("FCM Token:", token);

        const uuid = async () => {
          // generate a unique id for the browser
          let uuid = await localStorage.getItem("UUID");
          if (!uuid) {
            uuid = Math.random().toString(36).slice(2) + Date.now();
            localStorage.setItem("UUID", uuid);
          }
          return uuid;
        };

        const payLoad = {
          url: firebaseUrl,
          server_key: firebaseKey,
          device_token: token,
          device_id: uuid(),
          device_type: "web",
          notification_type: "direct",
          notification_agent: "firebase",
        };

        await dispatch(storeAPushNotification({ ...payLoad }));
      } catch (error) {
        console.error("Permission Denied", error);
      }
    };

    requestPermission();
  });

  return null;
}
