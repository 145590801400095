import React from "react";
import { useNavigate } from "react-router-dom";
import Container from "../../components/container/container";
import Button from "../../components/buttons/buttons";
import ErrorBg from "../../assets/images/pictures/error404-bg.png";
// import { ReactComponent as Image404 } from "../../assets/images/icons/404.svg";
import Image404 from "../../assets/images/icons/error-img.svg";

export default function Error404() {
  const navigate = useNavigate();
  return (
    <div
      className="bg-center bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${ErrorBg})` }}
    >
      <Container>
        <div className="pt-12 sm:pt-24 justify-center items-center mx-auto pb-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 items-center gap-10 sm:px-8">
            {/* <Image404 /> */}
            <img src={Image404} alt="404" />
            <div className="max-w-[620px]">
              <h3 className="text-7xl font-bold mb-2">Oops!</h3>
              <h4 className="text-3xl mb-5">We couldn’t find the page.</h4>
              <p className="text-xl">Maybe you can find what you need here</p>

              <div className="flex gap-4 mt-12">
                <Button.Primary
                  title={"Home"}
                  onClick={() => navigate("/user/home")}
                  className="px-4 sm:px-8"
                />
                {/* <Button.Primary title={"My Orders"} className="px-4 sm:px-8" /> */}
                {/* <Button.Primary
                  title={"Track Order"}
                  className="px-4 sm:px-8"
                /> */}
              </div>

              {/* <div className="sm:w-full mt-12">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="sm:relative">
                  <div className="sm:pointer-events-none sm:absolute sm:inset-y-0 sm:left-0 sm:pl-3 sm:flex sm:items-center">
                    <SEARCH
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="search"
                    name="search"
                    className="block w-full bg-white border border-gray-200 rounded-md py-2 pl-10 pr-3 :text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1  sm:text-sm"
                    placeholder="Search for restaurant, food, store, merchandise or more"
                    type="search"
                    onClick={() => navigate(`/search`)}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
