import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { pushNotificationService } from "../../services/pushNotification.service";
import notificationType from "./pushNotificationTypes";

/**
 * store A Push Notification
 **/

export const storeAPushNotification = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: notificationType["CREATING"], payLoad: true });
    const response = await pushNotificationService.createPushNotification(
      payLoad
    );
    dispatch({ type: notificationType["CREATING"], payLoad: false });
    dispatch({
      type: notificationType["CREATE_PUSH_NOTIFICATION"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: notificationType["CREATING"], payLoad: false });
    errorHandler(error, true);
  }
};
