import React from "react";
import { useLocation } from "react-router-dom";
import Container from "../container/container";
import Tabs from "../tabs/tabs";
import Placeholder1 from "../../assets/images/emptyState/restaurant-placeholder.jpg";
import Placeholder2 from "../../assets/images/emptyState/restaurant-placeholder1.jpg";
import Placeholder3 from "../../assets/images/emptyState/restaurant-placeholder2.jpg";

export default function Header({ restaurant, SearchFood }) {
  const location = useLocation();
  const foods = restaurant?.foods;

  function getFoodCategories() {
    let arr = [];
    for (let index = 0; index < foods?.length; index++) {
      if (!arr.includes(foods[index].food_categories[0])) {
        arr.push(foods[index].food_categories[0]);
      }
    }
    return arr;
  }

  let data = getFoodCategories();

  function selectBackgroundImage(apiImages) {
    // Predefined images to use if apiImages is empty
    const fallbackImages = [Placeholder1, Placeholder2, Placeholder3];

    // Check if apiImages array has any images
    if (apiImages?.length > 0) {
      // Randomly select one image from apiImages
      const randomIndex = Math.floor(Math.random() * apiImages?.length);
      return apiImages[randomIndex];
    } else {
      // Randomly select one of the fallback images
      const randomIndex = Math.floor(Math.random() * fallbackImages.length);
      return fallbackImages[randomIndex];
    }
  }

  const backgroundImage = selectBackgroundImage(restaurant?.restaurant_banners);

  return (
    <div
      className={
        location.pathname.includes("store") &&
        !location.pathname.includes("details")
          ? ""
          : "mb-12"
      }
    >
      <div
        className="min-h-96 bg-cover no-repeat bg-center relative z-0 dark-overlay"
        style={{
          backgroundImage: `url('${backgroundImage}')`,
        }}
      >
        <Container>
          <div className="py-16 sm:py-24">
            <h2
              className="text-5xl sm:text-7xl text-white font-bold mb-3 mt-16"
              style={{ lineHeight: "64px" }}
            >
              {restaurant?.restaurant_name
                ? restaurant?.restaurant_name
                : restaurant?.package_name}
            </h2>
            <div className="flex gap-4 mb-8 mt-[52px]"></div>
          </div>
        </Container>
      </div>

      <div
        className={
          location.pathname.includes("restaurant") &&
          !location.pathname.includes("details")
            ? "block sticky top-0"
            : "hidden mb-8"
        }
      >
        <div className="max-w-7xl mx-auto sm:px-4">
          <main className="-mt-16 pb-6 relative z-10">
            <Tabs data={data} SearchFood={SearchFood} />
          </main>
        </div>
      </div>
    </div>
  );
}
