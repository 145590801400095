import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTeamFromCookies } from "../../../utils/Auth";

const titles = [
  {
    name: "Web Sliders",
    href: "sliders",
    text: "Manage Application Advertisements",
    type: "show",
  },
  {
    name: "Categories",
    href: "categories",
    text: "Create, edit & remove categories.",
    type: "show",
  },
  {
    name: "Tags",
    href: "tags",
    text: "Create, edit & remove tags.",
    type: "show",
  },
  {
    name: "Roles",
    href: "roles",
    text: "Create roles, add permissions here.",
    type: "hidden",
  },
  {
    name: "Manage User Roles",
    href: "user-roles",
    text: "Assign or Un-assign user roles.",
    type: "show",
  },
  {
    name: "Rates Settings",
    href: "rate",
    text: "Set, adjust and update rates.",
    type: "show",
  },
  {
    name: "Arrangement Settings",
    href: "arrangement",
    text: "Set application data arrangement.",
    type: "show",
  },
  {
    name: "Advanced Settings",
    href: "application",
    text: "Modify application settings",
    type: "show",
  },
  {
    name: "Become Employee",
    href: "become-employee",
    text: "Designate or convert users to employees",
    type: "show",
  },
  // {
  //   name: "Enhanced Application Settings",
  //   href: "enhanced-application",
  //   text: "Modify application settings",
  //   type: "show",
  // },
  // {
  //   name: "Notify",
  //   href: "broadcast-notification",
  //   text: "Send emails and notifications",
  //   type: "hidden",
  // },
  // {
  //   name: "Feedback",
  //   href: "feedback",
  //   text: "See customer feedbacks here",
  //   type: "show",
  // },
  // {
  //   name: "KYC",
  //   href: "kyc-verification",
  //   text: "User identity verification",
  //   type: "show",
  // },
  // {
  //   name: "Platform Rates",
  //   href: "platform-rates",
  //   text: "Modify various platform rates",
  //   type: "show",
  // },
];

export default function NewSettings() {
  const navigate = useNavigate();
  const { active_tab } = useParams();
  const team = getTeamFromCookies();

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/settings/${tab.href}`);
    }
  };

  return (
    <div>
      <div className="bg-white min-h-[906px] rounded-lg px-6 py-6">
        <div>
          <h2 className=" text-2xl font-bold py-3 px-2">Settings</h2>
          <p className="text-[#A3AED0] py-2 px-2 mb-6">
            The settings page provides a centralized location for the
            administrator to manage various aspects of the business, the
            business information, application settings and support needs. The
            purpose of the page is to make it easier for administration to
            manage their information and ensure that products and business
            information is up-to-date and accurate.
          </p>
        </div>
        <div className="grid sm:grid-cols-4 gap-4 sm:gap-8">
          {titles.map((title, i) => (
            <div
              key={i}
              className={
                title?.type === "hidden" && team !== "administrator"
                  ? "hidden"
                  : "block w-full sm:max-w-[268px] sm:h-24 rounded-xl shadow-lg px-2 py-6 bg-white cursor-pointer"
              }
              onClick={() => {
                toggle(title);
              }}
            >
              <div className="mb-6 items-center">
                <p className="text-sm text-[#a3aed0] font-medium">
                  {title.text}
                </p>
                <p className="text-[#2B3674]">{title.name}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
