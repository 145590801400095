import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Button from "../../../components/buttons/buttons";
import { Beat } from "../../../plugins/spinners.plugin";
import { getTeamFromCookies } from "../../../utils/Auth";
import { expiredCoupon, showCoupon } from "../../../redux/coupons/couponAction";

export default function SingleCoupon() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const team = getTeamFromCookies();
  const { id } = useParams();

  const { deleting, singleCoupon } = useSelector((state) => state.coupon);

  useEffect(() => {
    if (id) {
      dispatch(showCoupon(id));
    }
    // eslint-disable-next-line
  }, [id]);

  const handleDelete = async () => {
    const response = await dispatch(expiredCoupon(id));
    if (response) {
      navigate("/admin/coupons/all/1");
    }
  };

  return (
    <>
      <div className="border w-full sm:w-full border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-2 items-center flex justify-between">
          <h3 className="text-base sm:text-lg font-medium">Coupon Details</h3>
          {team === "administrator" && (
            <Button.Primary
              type={"button"}
              disabled={deleting}
              title={deleting ? <Beat color={"#ffffff"} /> : "Expire Coupon"}
              onClick={handleDelete}
            />
          )}
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-6 gap-4 sm:gap-8 mt-3 sm:mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Title</label>
            <p className="text-[#151515] text-sm">{singleCoupon?.title}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Slug</label>
            <p className="text-[#151515] text-sm">{singleCoupon?.slug}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Cap</label>
            <p className="text-[#151515] text-sm">
              {singleCoupon?.min_order_amount_cap}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Amount</label>
            <p className="text-[#151515] text-sm">{singleCoupon?.amount}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Created on </label>
            <p className="text-[#151515] text-sm">
              {moment
                .utc(singleCoupon?.created_at)
                .format("DD, MMM YYYY, HH:MM A")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Expires</label>
            <p className="text-[#151515] text-sm">
              {moment.utc(singleCoupon?.expiry).format("DD, MMM YYYY, HH:MM A")}
            </p>
          </div>
        </div>
      </div>
      {singleCoupon?.user && (
        <div className="border w-full border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
          <div className="border-b border-[#E4E4F3] py-2 items-center">
            <h3 className="text-base sm:text-lg font-medium">
              Recipient Details
            </h3>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-8 mt-3 sm:mt-6">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.user?.name}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.user?.email}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Phone Number
              </label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.user?.phone
                  ? singleCoupon?.user?.phone
                  : "Not available"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
              {singleCoupon?.user?.blocked === false ? (
                <p className="text-[#151515] text-sm">Active</p>
              ) : (
                <p className="text-[#151515] text-sm">Blocked</p>
              )}
            </div>
          </div>
        </div>
      )}
      {singleCoupon?.restaurant && (
        <div className="border w-full border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
          <div className="border-b border-[#E4E4F3] py-2 items-center">
            <h3 className="text-base sm:text-lg font-medium">
              {singleCoupon?.restaurant?.restaurant_name}
            </h3>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-5 gap-4 sm:gap-8 mt-3 sm:mt-6">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Restaurant ID
              </label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.restaurant?.id}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.restaurant?.restaurant_email}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Phone Number
              </label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.restaurant?.restaurant_phone
                  ? singleCoupon?.restaurant?.restaurant_phone
                  : "Not available"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Flag Count</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.restaurant?.flag}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
              <p className="text-[#151515] text-sm">
                {moment(singleCoupon?.restaurant?.created_at).format(
                  "DD, MMM YYYY, HH:MM A"
                )}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Visibility</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.restaurant?.visibility}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.restaurant?.restaurant_country}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">State</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.restaurant?.restaurant_state}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">City</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.restaurant?.restaurant_city}
              </p>
            </div>
          </div>
        </div>
      )}
      {singleCoupon?.store && (
        <div className="border w-full border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
          <div className="border-b border-[#E4E4F3] py-2 items-center">
            <h3 className="text-base sm:text-lg font-medium">
              {singleCoupon?.store?.store_name}
            </h3>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-5 gap-4 sm:gap-8 mt-3 sm:mt-6">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Store ID</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.store?.id}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.store?.store_email}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Phone Number
              </label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.store?.store_phone
                  ? singleCoupon?.store?.store_phone
                  : "Not available"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Flag Count</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.store?.flag}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
              <p className="text-[#151515] text-sm">
                {moment(singleCoupon?.store?.created_at).format(
                  "DD, MMM YYYY, HH:MM A"
                )}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Visibility</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.store?.visibility}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.store?.store_country}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">State</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.store?.store_state}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">City</label>
              <p className="text-[#151515] text-sm">
                {singleCoupon?.store?.store_city}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
