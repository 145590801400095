import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import All from "./tabs/all";

export default function FeatureReport() {
  const DEFAULT_ACTIVE_TAB = "all";
  const navigate = useNavigate();
  const { active_tab } = useParams();

  useEffect(() => {
    if (!active_tab) {
      navigate(`/admin/feature-report/${DEFAULT_ACTIVE_TAB}/1`);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div data-test="feature-report-page">
      {/* <div className="mb-8">
        <h1 className="text-3xl font-semibold text-gray-900">Users Reports</h1>
      </div> */}
      {/* <div className="sm:block">
        <div className="border-b border-gray-200"></div>
      </div> */}

      <div className="mt-4">
        <All />
      </div>
    </div>
  );
}
