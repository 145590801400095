import React, { useMemo, useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Button from "../../../../components/buttons/buttons";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import EmptyState from "../../../../assets/images/emptyState/category-list.jpg";
import { filterAddressesAction } from "../../../../redux/address/addressActions";
import { filterReferralsAction } from "../../../../redux/referral/referralActions";
import { ReactComponent as Chart } from "../../../../assets/images/icons/chart.svg";

export default function UserReportDetail() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const user = location.state.user;

  const [data, setData] = useState([]);

  const { allAddresses } = useSelector((state) => state.address);
  const { allReferrals, filtering } = useSelector((state) => state.referral);

  const onImageError = (e) => {
    e.target.src = EmptyState;
  };

  useEffect(() => {
    dispatch(filterAddressesAction({ user_id: user?.id }));
    dispatch(filterReferralsAction({ referral_code: user?.name }));
    // eslint-disable-next-line
  }, [user?.id]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((referral, index) => {
        let show = {
          id: index + 1,
          user_id: referral?.user_id,
          count: referral?.referral_count,
          code: referral?.referral_code,
          referrer_code: referral?.referrer_code ?? "No referee",
          created: (
            <span>{moment(referral?.created_at).format("MMM DD, YYYY")}</span>
          ),
          user: user,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allReferrals?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReferrals]);

  const referralColumns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "User ID",
            accessor: "show.user_id",
          },
          {
            Header: "Referral Count",
            accessor: "show.count",
          },
          {
            Header: "Referral Code",
            accessor: "show.code",
          },
          {
            Header: "Referee Code",
            accessor: "show.referrer_code",
          },
          {
            Header: "Created On",
            accessor: "show.created",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="userProfile-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Orders Report</h3>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-5">
          <div
            className="rounded-md py-8 px-4 shadow-lg shadow-blue-grey-50 cursor-pointer text-center md:text-left"
            onClick={() =>
              navigate(`/admin/users-report/${id}/restaurant-orders/shipped/1`)
            }
          >
            <span className="text-[#2B3674]">Restaurant Orders</span>
          </div>
          <div
            className="rounded-md py-8 px-4 shadow-lg shadow-blue-grey-50 cursor-pointer text-center md:text-left"
            onClick={() =>
              navigate(`/admin/users-report/${id}/store-orders/shipped/1`)
            }
          >
            <span className="text-[#2B3674]">Store Orders</span>
          </div>
          <div
            className="rounded-md py-8 px-4 shadow-lg shadow-blue-grey-50 cursor-pointer text-center md:text-left"
            onClick={() =>
              navigate(
                `/admin/users-report/${id}/food-package-orders/shipped/1`
              )
            }
          >
            <span className="text-[#2B3674]">Food Package Orders</span>
          </div>
          <div
            className="rounded-md py-8 px-4 shadow-lg shadow-blue-grey-50 cursor-pointer text-center md:text-left"
            onClick={() =>
              navigate(
                `/admin/users-report/${id}/merchandise-package-orders/shipped/1`
              )
            }
          >
            <span className="text-[#2B3674]">Merchandise Package Orders</span>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">User Details</h3>
          <Button.Primary
            type="button"
            title={
              <span className="flex gap-1 items-center">
                <Chart />
                Check Stats
              </span>
            }
            onClick={() => navigate(`/admin/users-report/${id}/statistics`)}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-5 gap-8 mt-6">
          <figure className="min-h-[150px] xl:max-h-[164px] w-auto xl:max-w-[200px] border border-[#E4E4F3] rounded-sm">
            <img
              className="object-cover h-full w-full rounded"
              src={user?.picture ?? EmptyState}
              alt="User"
              onError={onImageError}
            />
          </figure>

          <div className="col-span-4 grid grid-cols-2 sm:grid-cols-5 gap-8">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
              <p className="text-[#151515] text-sm">{user?.name}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
              <p className="text-[#151515] text-sm">{user?.email}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Phone number
              </label>
              <p className="text-[#151515] text-sm">{user?.phone ?? "N/A"}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Location </label>
              <p className="text-[#151515] text-sm">
                {user?.profile?.city ?? "N/A"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Birthday</label>
              <p className="text-[#151515] text-sm">
                {user?.birth_date ?? "N/A"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Orders</label>
              <p className="text-[#151515] text-sm">N/A</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Coupons</label>
              <p className="text-[#151515] text-sm">N/A</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Referrals</label>
              <p className="text-[#151515] text-sm">N/A</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
              <p className="text-[#151515] text-sm">N/A</p>
            </div>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Delivery Address</h3>
        </div>
        {allAddresses?.data?.length > 0 ? (
          allAddresses?.data?.map((address, index) => (
            <div key={index} className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
                <p className="text-[#151515] text-sm">{address?.address}</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Location</label>
                <p className="text-[#151515] text-sm">{address?.city}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="font-medium py-4 text-center">
            No delivery addresses available
          </p>
        )}
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Referrals</h3>
        </div>
        <div className="bg-white rounded-md py-4">
          {filtering ? (
            <TableSkeleton />
          ) : (
            <Table
              columns={referralColumns}
              data={data?.length > 0 ? data : []}
              className="w-full"
            />
          )}
        </div>
      </div>
    </div>
  );
}
