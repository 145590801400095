import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../assets/images/icons/close.svg";
import Location from "../../assets/images/icons/Location.svg";

export function SelectLocation({ isOpen, setIsOpen }) {
  const user_city = localStorage.getItem("ogwugo-user-city");

  function closeModal() {
    setIsOpen(false);
  }

  const setLocation = (params) => {
    localStorage.setItem("ogwugo-user-city", params);
    window.location.reload();
  };

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successFunction, errorFunction);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  function successFunction(position) {
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    reverseGeocode(lat, lng);
  }

  function errorFunction(error) {
    alert("Geocoder failed due to: " + error.message);
  }
  function reverseGeocode(lat, lng) {
    var xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      "https://nominatim.openstreetmap.org/reverse?format=json&lat=" +
        lat +
        "&lon=" +
        lng,
      true
    );
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        var response = JSON.parse(xhr.responseText);
        var city =
          response.address.city ||
          response.address.town ||
          response.address.village ||
          response.address.county;

        localStorage.setItem("ogwugo-user-city", city);
        window.location.reload();
      }
    };
    xhr.send();
  }

  return (
    <div data-test="createBusiness">
      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative sm:w-[550px] bg-white rounded-3xl px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-10 sm:pb-6 w-full ">
                  <div className="sm:block absolute top-1 sm:top-3 right-4 pt-4">
                    <button
                      type="button"
                      className="bg-[#ffeae5] rounded-md p-2 sm:p-3 "
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close</span>
                      <img
                        src={CLOSE}
                        alt="close"
                        className=" w-3 h-3 sm:w-4 sm:h-4"
                      />
                    </button>
                  </div>
                  <div className="sm:flex ">
                    <div className=" text-center mx-auto justify-center mb-2 mt-3">
                      <Dialog.Title
                        as="h3"
                        className=" text-2xl font-bold text-black"
                      >
                        Ogwugo Cities
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-3 ">
                    <div className="mt-4 border-b-2" />
                    <div className="mt-6 ">
                      <div className="ml-3 flex justify-between text-left items-center mt-8 mb-4">
                        <label htmlFor={`location-radio-enugu`}>
                          <div className="flex items-center">
                            <img src={Location} alt="location logo" />
                            <div className="ml-3">
                              <p className="font-medium text-lg text-[#344B67]">
                                Use My Current Location
                              </p>
                              <p className="text-sm text-[#344B67]">
                                Let Ogwugo use your location
                              </p>
                            </div>
                          </div>
                        </label>

                        <input
                          id={`location-radio-enugu`}
                          name="location-radio-group"
                          type={"radio"}
                          defaultChecked={
                            user_city &&
                            user_city !== "enugu" &&
                            user_city !== "nsukka"
                          }
                          className="w-6 h-6 text-primary bg-white border-primary focus:ring-primary"
                          onChange={() => getLocation()}
                        />
                      </div>

                      <div className="ml-3 flex justify-between text-left items-center mt-8 mb-4">
                        <label htmlFor={`location-radio-enugu`}>
                          <div className="flex items-center">
                            <img src={Location} alt="location logo" />
                            <div className="ml-3">
                              <p className="font-medium text-lg">Enugu</p>
                            </div>
                          </div>
                        </label>
                        <input
                          id={`location-radio-enugu`}
                          name="location-radio-group"
                          type={"radio"}
                          defaultChecked={user_city === "enugu"}
                          className="w-6 h-6 text-primary bg-white border-primary focus:ring-primary"
                          onChange={() => setLocation("enugu")}
                        />
                      </div>
                      <div className="ml-3 flex justify-between text-left items-center mt-8 mb-4">
                        <label htmlFor={`location-radio-nsukka`}>
                          <div className="flex items-center">
                            <img src={Location} alt="location logo" />
                            <div className="ml-3">
                              <p className="font-medium text-lg">Nsukka</p>
                            </div>
                          </div>
                        </label>

                        <input
                          id={`location-radio-nsukka`}
                          name="location-radio-group"
                          type={"radio"}
                          defaultChecked={user_city === "nsukka"}
                          className="w-6 h-6 text-primary bg-white border-primary focus:ring-primary"
                          onChange={() => setLocation("nsukka")}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
