import React from "react";
// import UPLOAD from "../../assets/images/icons/upload.svg";
// import { UPLOADIMG } from "../../assets/images/pictures/images";
// import Button from "../../components/buttons/buttons";
import Container from "../../components/container/container";
// import { useLocation } from "react-router-dom";

export function BankTransfer() {
  // const location = useLocation();
  //const dispatch = useDispatch();
  // const totalPrice = location.state.totalPrice;

  return (
    <Container>
      <div>
        <div className="px-6 flex justify-center">
          <div className="relative w-[358px] bg-white rounded-sm border px-4 pt-5 pb-4 text-center overflow-hidden shadow-md transform transition-all sm:my-10 sm:max-w-lg sm:w-full  sm:p-8">
            <div className="sm:flex sm:items-start text-center justify-center border-b-2 mb-6">
              <div className=" text-center">
                <h3 className="text-center text-base font-medium mb-4  text-black">
                  Pay with Bank Transfer
                </h3>
              </div>
            </div>
            {/* <div>
              <p className=" text-lg mb-2">
                Transfer <span className="font-bold">{totalPrice}</span> to:
              </p>
              <div className="bg-[#fff2f0] rounded-md py-6 mb-6">
                <p className=" mt-2 text-xs mb-2">Account details:</p>
                <p className=" font-medium text-base">
                  United Bank for Africa (UBA)
                </p>
                <p className=" font-bold text-2xl mb-6">0098490280</p>
                <p className=" mt-2 text-xs mb-2">Account Name:</p>
                <p className=" font-medium text-base">Ogwugo Limited</p>
              </div>
              <p className=" text-base text-gray-500 mb-6 sm:[max-w-[327px]">
                After making payment, please send a screenshot for verification
                and upload by clicking below.
              </p>
              <div className="bg-[#fffbfa] py-4 mb-3 border border-gray-100 rounded-md">
                <img className="mx-auto mb-2" src={UPLOAD} alt="" />
                <p className=" text-sm text-gray-500">
                  Click here to select a photo
                </p>
              </div>
              <img src={UPLOADIMG} alt="uploading " />
            </div> */}
          </div>
        </div>
        {/* <div className="flex justify-center">
          <Button.Primary
            title={"Confirm Payment"}
            className={"w-80 mt-14 sm:mt-1 sm:h-[60px] sm:w-[376px]"}
          />
        </div> */}
      </div>
    </Container>
  );
}
