import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Restaurant from "./outlets/restaurant";
import RestaurantPackage from "./outlets/restaurant-package";
import Store from "./outlets/store";
import StorePackage from "./outlets/store-package";

const tabs = [
  { name: "Restaurant", href: "restaurant" },
  { name: "Store", href: "store" },
  { name: "Food Package", href: "food-package" },
  { name: "Merchandise Package", href: "merchandise-package" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function OrdersReport() {
  const navigate = useNavigate();
  const { active_tab } = useParams();

  useEffect(() => {
    if (!active_tab) {
      navigate(`/admin/orders-report/restaurant/1`);
    }
    // eslint-disable-next-line
  }, [active_tab]);

  const toggle = (tab) => {
    if (active_tab !== tab.href) {
      navigate(`/admin/orders-report/${tab.href}/1`);
    }
  };

  return (
    <div data-test="food-page">
      {/* <div className="mb-8">
        <h1 className="text-3xl font-semibold text-gray-900">Orders Reports</h1>
      </div> */}
      <div className="sm:block">
        <div className="border-b border-gray-200 w-full">
          <nav
            className="-mb-px flex space-x-8 overflow-x-scroll"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>

      <div>
        {active_tab === "restaurant" && <Restaurant />}
        {active_tab === "store" && <Store />}
        {active_tab === "food-package" && <RestaurantPackage />}
        {active_tab === "merchandise-package" && <StorePackage />}
      </div>
    </div>
  );
}
