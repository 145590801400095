import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../buttons/buttons";
// import CLOSE from "../../../assets/images/icons/close.svg";

export default React.forwardRef(function GuestPaymentSuccess(
  {
    visibility = false,
    primaryButtonTitle,
    primaryButton,
    primaryButtonStyle,
    primaryButtonClassName,
    modalTitle,
    modalTitleSection,
    modelContent,
    modelContentSection,
    disableClose = false,
    disableOpen = false,
    onOpen = () => {},
    onClose = () => {},
    paymentRef,
  },
  ref
) {
  // Ref handler
  React.useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
  }));

  const navigate = useNavigate();
  let [isOpen, setIsOpen] = React.useState(visibility);

  function closeModal() {
    !disableClose && setIsOpen(false);
    !disableClose && onClose();
  }

  function openModal() {
    !disableOpen && setIsOpen(true);
    !disableOpen && onOpen();
    // paymentRef.current.close();
  }

  return (
    <React.Fragment>
      <div className="">
        {primaryButton ? (
          <span className="cursor-pointer" onClick={openModal}>
            {primaryButton}
          </span>
        ) : (
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={openModal}
            disabled={primaryButtonStyle?.disabled}
            className={
              "glade-normal-text-three glade-button-blue whitespace-nowrap w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
              primaryButtonClassName
            }
          >
            {primaryButtonTitle ?? "Title"}
          </button>
        )}
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full bg-white rounded-sm px-4 pt-5 pb-4  overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-[800px] sm:min-h-[400px] sm:p-8">
                  {/* <div className="sm:block absolute top-2 right-3 pt-4 pr-4">
                    <button type="button" className="" onClick={closeModal}>
                      <span className="sr-only">Close</span>
                      <img
                        src={CLOSE}
                        alt="close"
                        className=" w-3 h-3 sm:w-4 sm:h-4"
                      />
                    </button>
                  </div> */}
                  <div className="sm:flex sm:items-start">
                    <div className="text-center mx-auto ">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-medium text-black"
                      >
                        Payment Successful
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="flex justify-center my-10">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="130"
                      height="130"
                      viewBox="0 0 130 130"
                      fill="none"
                    >
                      <path
                        opacity="0.1"
                        d="M95.3914 0H34.6666C12.5378 0 0 12.5378 0 34.6666V95.3335C0 117.462 12.5378 130 34.6666 130H95.3914C117.52 130 130 117.462 130 95.3335V34.6666C130 12.5378 117.52 0 95.3914 0Z"
                        fill="#00D220"
                      />
                      <path
                        d="M65 32.5C57.3625 32.5 50.2938 35.1813 44.6875 39.65C37.2125 45.5813 32.5 54.7625 32.5 65C32.5 71.0938 34.2063 76.8625 37.2125 81.7375C42.8188 91.1625 53.1375 97.5 65 97.5C73.2062 97.5 80.6813 94.4938 86.3688 89.375C88.8875 87.2625 91.0812 84.6625 92.7875 81.7375C95.7938 76.8625 97.5 71.0938 97.5 65C97.5 47.0437 82.9562 32.5 65 32.5ZM81.8188 61.5062L64.5125 77.5125C63.375 78.5688 61.8312 79.1375 60.3687 79.1375C58.8249 79.1375 57.2812 78.5688 56.0624 77.35L48.0188 69.3063C45.6625 66.95 45.6625 63.05 48.0188 60.6937C50.375 58.3375 54.275 58.3375 56.6313 60.6937L60.5312 64.5938L73.5312 52.5687C75.9688 50.2937 79.8688 50.4563 82.1437 52.8938C84.4188 55.3313 84.2563 59.2312 81.8188 61.5062Z"
                        fill="#00D220"
                      />
                    </svg>
                  </div>

                  <p className="font-bold text-lg">
                    Your payment was successful
                  </p>

                  <Button.Primary
                    title={"Go to Ogwugo Home"}
                    className={"w-full h-[48px] px-4 mt-6 mb-2"}
                    onClick={() => navigate(`/user/home`)}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
});
