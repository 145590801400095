import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/buttons/buttons";
import Input from "../../../../components/inputs/inputs";
import { history } from "../../../../helpers/history";
import { Beat } from "../../../../plugins/spinners.plugin";
import Search from "../../../../assets/images/icons/search.svg";
import AddMerchVariationModal from "../../../../components/modals/admin/addMerchVariationModal";
import { createAMerchandise } from "../../../../redux/merchandise/merchandiseActions";
import { cleanInput } from "../../../../helpers/cleanInput";
import SelectableInput from "../../../../components/inputs/SelectableInput";
import Warning from "../../../../assets/images/icons/warning-circle.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/images/icons/upload.svg";
import RemoveMerchAttributeModal from "../../../../components/modals/admin/attributes/removeMerchAttributeModal";
import { searchAllStores } from "./../../../../redux/stores/storeActions";
import storeType from "../../../../redux/stores/storeTypes";
import { filterProductCategory } from "../../../../redux/productCatergory/productCategoryAction";
import { filterProductTag } from "../../../../redux/productTags/productTagActions";
import { filterAttributeAction } from "../../../../redux/attribute/attributeActions";

export default function CreateAdminMerchandise() {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);

  let { searchStore } = useSelector((state) => state.store);
  const { allCategories } = useSelector((state) => state.productCatergory);
  const { allTags } = useSelector((state) => state.productTag);
  const { isLoading } = useSelector((state) => state.merchandise);

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState({ search: "" });
  const [selected, setSelected] = useState([]);
  const [selectedArr, setSelectedArr] = useState([]);
  const [displayArr, setDisplayArr] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState([]);
  const [data, setData] = useState({
    store_id: "",
    business_id: "",
    merchandise_name: "",
    merchandise_description: "",
    merchandise_categories: [],
    merchandise_tags: [],
    merchandise_amount: "",
    merchandise_operating_status: "",
    merchandise_discount: 0,
    merchandise_attributes: [],
    stock: "",
    base64_photos: "",
    format: "",
  });
  const {
    merchandise_name,
    merchandise_description,
    merchandise_categories,
    merchandise_tags,
    merchandise_amount,
    stock,
    store_id,
    format,
  } = data;

  useEffect(() => {
    dispatch(filterProductCategory({ type: "store" }, 1));
    dispatch(filterProductTag({ type: "store" }, 1));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data?.business_id) {
      dispatch(filterAttributeAction({ business_id: data?.business_id }));
    }
    // eslint-disable-next-line
  }, [data?.business_id]);

  const storeCategories = allCategories?.data?.filter((item) => {
    return item?.type === "store";
  });
  const storeTags = allTags?.data?.filter((item) => {
    return item?.type === "store";
  });

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    const newImageUrls = [];
    const newImage64 = [];
    images?.forEach((image) => {
      newImageUrls.push(URL.createObjectURL(image));
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        newImage64.push(reader.result);
      };
    });
    setImageUrls(newImageUrls);
    setImage64(newImage64);
  }, [images]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    event.preventDefault();
    setData((data) => ({ ...data, [name]: value }));
  };

  const handleToggleTags = (value) => {
    const former_tags = [...data?.merchandise_tags];
    if (former_tags.includes(value)) {
      let arr = [...former_tags];
      const filtered = arr.filter((item) => item !== value);
      setData({ ...data, merchandise_tags: filtered });
    } else {
      let new_tags = [...former_tags, value.name];
      setData({ ...data, merchandise_tags: new_tags });
    }
  };

  const handleToggleCategories = (value) => {
    const former_categories = [...data?.merchandise_categories];
    if (former_categories.includes(value)) {
      let arr = [...former_categories];
      const filtered = arr.filter((item) => item !== value);
      setData({ ...data, merchandise_categories: filtered });
    } else {
      let new_categories = [...former_categories, value.name];
      setData({ ...data, merchandise_categories: new_categories });
    }
  };

  function getFoodWithCategories() {
    let titleArr = [];
    let newArray = [];
    for (let index = 0; index < selected?.length; index++) {
      if (!titleArr.includes(selected[index].title)) {
        titleArr.push(selected[index].title);
      }
    }
    for (let index = 0; index < titleArr?.length; index++) {
      let temp = {
        title: titleArr[index],
        attributes: selected.filter((food) => titleArr[index] === food.title),
      };
      newArray.push(temp);
    }
    return newArray;
  }

  useEffect(() => {
    const merchAttr = getFoodWithCategories();
    setDisplayArr(merchAttr);
    // eslint-disable-next-line
  }, [selected]);

  // eslint-disable-next-line
  const { search } = searchValue;

  const handleSearch = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setSearchValue((searchValue) => ({ ...searchValue, [name]: value }));
    if (value.length >= 3) {
      dispatch(
        searchAllStores({
          search: value,
        })
      );
    } else if (value.length <= 3) {
      searchStore = { data: [] };
      dispatch({ type: storeType["SEARCH_STORES"], payLoad: searchStore });
    }
  };

  function fillStore(result) {
    setData((data) => ({
      ...data,
      store_id: result.id,
      business_id: result.business_id,
    }));
    var mydiv = document.getElementById(result.id);
    if (result.id) {
      mydiv.style.borderColor = "border-[#D14522]";
    }

    setSearchValue((searchValue) => ({
      search: result.store_name,
    }));

    searchStore = { data: [] };
    dispatch({ type: storeType["SEARCH_STORES"], payLoad: searchStore });
  }
  
  const handleSubmit = async () => {
    setSubmitted(true);
    let newData = {
      ...data,
      base64_photos: image64 ? image64 : [],
      merchandise_attributes: selectedArr,
    };
    cleanInput(newData);

    if (
      merchandise_name &&
      merchandise_description &&
      merchandise_description.length > 10 &&
      merchandise_categories &&
      merchandise_amount &&
      merchandise_tags
    ) {
      try {
        const response = await dispatch(
          createAMerchandise({
            ...newData,
          })
        );
        if (response?.status === "success") {
          history(`/admin/merchandise/unapproved/1`);
        }
      } catch (error) {
        console.log(error);
        history(`/admin/merchandise/unapproved/1`);
      }
    }
  };
  return (
    <>
      {/* <div className="mb-10 flex justify-between">
        <h1 className="text-3xl font-semibold text-gray-900">
          Merchandise / Create Merchandise
        </h1>
      </div> */}
      <form>
        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Merchandise Details</h3>
            <Button.Primary
              title={
                isLoading ? <Beat color={"#ffffff"} /> : "Create Merchandise"
              }
              form="merch_form"
              onClick={handleSubmit}
              type={"button"}
              disabled={isLoading}
              className="rounded-sm"
            />
          </div>

          <div className="mt-6">
            <div className="mt-2 mb-3 relative">
              <label className="block text-sm font-medium text-[#151515]">
                Store Name
              </label>
              <input
                type={"search"}
                name={"search"}
                htmlFor={"store_name"}
                value={searchValue.search}
                className={
                  "mt-2 block pl-3 pr-10 w-full text-base bg-white focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                }
                placeholder={"Search Store"}
                onChange={handleSearch}
              />
              <img
                src={Search}
                alt="search-icon"
                className=" fa-search w-3 h-3 absolute top-2 right-4"
              />
              {submitted && !store_id && (
                <div className="text-primary text-xs ">Store required</div>
              )}
            </div>
            {searchStore &&
              Array.isArray(searchStore) &&
              searchStore.map((result, index) => (
                <div
                  className="p-3 bg-white mb-2  border"
                  id={result.id}
                  key={index}
                  onClick={() => fillStore(result)}
                >
                  <div className=" grid-cols-3">
                    <div className="col-span-1">
                      <div className="">
                        <h6 className="text-sm font-meduim">
                          {result.store_name}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              
            <div
              className={
                submitted && !merchandise_name ? "  border-red-500" : ""
              }
            >
              <Input.Label
                title={"Merchandise Name*"}
                htmlFor={"merchandise_name"}
                name="merchandise_name"
                onChange={handleChange}
                placeholder="Enter merchandise name"
              />
              {submitted && !merchandise_name && (
                <div className="text-primary text-xs ">
                  Merchandise name is required
                </div>
              )}
            </div>
            <div
              className={
                submitted && merchandise_description ? "  border-red-500" : ""
              }
            >
              <Input.Label
                title={
                  "Short Description (Hint: Input product Highlights/Features in bullets. Not more than 200 Characters)"
                }
                htmlFor={"merchandise_description"}
                name="merchandise_description"
                defaultValue={data.merchandise_description}
                onChange={handleChange}
                placeholder="Enter a short merchandise description"
              />
              {merchandise_description &&
                merchandise_description.length < 10 && (
                  <div className="text-xs text-red-500">
                    Merchandise description must be a minimum than 10 characters
                  </div>
                )}
              {submitted && !merchandise_description && (
                <div className="text-xs text-red-500">
                  Merchandise description is required
                </div>
              )}
            </div>

            <div className="grid grid-cols-1 ">
              <Input.Label
                title={"Stock*"}
                htmlFor={"stock"}
                name="stock"
                placeholder="Enter Quantity"
                onChange={handleChange}
              />
              {submitted && !stock && (
                <div className="text-primary text-xs ">Stock is required</div>
              )}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <div
                className={
                  submitted && !merchandise_amount ? "  border-red-500" : ""
                }
              >
                <Input.Number
                  title={"Price*"}
                  htmlFor={"merchandise_amount"}
                  name="merchandise_amount"
                  placeholder="Enter merchandise price"
                  onChange={handleChange}
                />
                {submitted && !merchandise_amount && (
                  <div className="text-primary text-xs ">
                    Merchandise amount is required
                  </div>
                )}
              </div>
              <div>
                <Input.Number
                  title={"Discounted Price"}
                  name="merchandise_discount"
                  htmlFor={"merchandise_discount"}
                  onChange={handleChange}
                  placeholder="0"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <Input.Select
                title={"Product Type"}
                htmlFor={"format"}
                name={"format"}
                value={format}
                onChange={handleChange}
              >
                <option>Select Product Type</option>
                <option value={"physical"}>Physical Product</option>
                <option value={"digital"}>Digital Product</option>
              </Input.Select>

              <Input.Select
                title={"Split Payment Available?"}
                // htmlFor={"format"}
                // name={"format"}
                // value={format}
                // onChange={handleChange}
              >
                <option value={"physical"}>No</option>
                {/* <option value={"digital"}>Yes</option> */}
              </Input.Select>
            </div>

            {/* Split payment section */}
            <div className="mt-8">
              {/* <div className="py-3 mb-2 flex justify-between items-center">
                <h3 className="text-lg font-medium">*Split Payment Setup*</h3>
                <Button.Primary
                  title={
                    isLoading ? <Beat color={"#ffffff"} /> : "Close Process"
                  }
                  onClick={handleSubmit}
                  className="px-5"
                  type={"button"}
                  disabled={isLoading}
                />
              </div>
              <Input.Label
                title={"Split Payment Name"}
                type="text"
                name="restaurant_id"
                id="restaurant_id"
                className="w-full border border-[#E4E4F3] bg-[#F3F3F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="This is the designated name for describing the plan in notification messages."
                onChange={handleChange}
              />
              <Input.Number
                title={"Initial Payment"}
                type="number"
                name="food_amount"
                id="food_amount"
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="This is the initial payment required before releasing the product to the customer."
                onChange={handleChange}
              />
              <Input.Number
                title={"Subsequent Payment Amount (£)"}
                type="number"
                name="food_amount"
                id="food_amount"
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="This is the fee for each recurring payment from the customer"
                onChange={handleChange}
              />
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                <div>
                  <Input.Number
                    title={"Payment Frequency/Intervals"}
                    type="number"
                    name="food_amount"
                    id="food_amount"
                    className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                    placeholder="This can be monthly, quarterly, yearly e.t.c"
                    onChange={handleChange}
                  />
                </div>

                <Input.Number
                  title={"Duration"}
                  type="number"
                  name="food_discount"
                  id="food_discount"
                  className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                  placeholder="This is the duration the plan should endure in terms of intervals"
                  onChange={handleChange}
                />
              </div> */}

              {/* notice */}
              {/* <div className="mt-1 py-2 flex gap-3">
                <img
                  src={Warning}
                  alt="warning"
                  className="w-[18px] h-[18px]"
                />
                <p className="text-sm text-[#333333]">
                  Without a specified duration, we'll bill the customer until
                  cancellation.
                </p>
              </div> */}
              {/* notice */}
              <div className="mt-1 py-2 flex gap-3">
                <img
                  src={Warning}
                  alt="warning"
                  className="w-[18px] h-[18px]"
                />
                <p className="text-sm text-[#333333]">
                  The default currency for charging is set to Naira
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="border border-[#E4E4F3] bg-white  rounded-md py-5 px-8 mt-8">
          <div
            className={
              submitted && !merchandise_categories ? "  border-red-500" : ""
            }
          >
            <label
              htmlFor="merchandise_categories"
              className="text-sm font-medium text-[#151515] block mb-2"
            >
              Merchandise Categories
            </label>
            <SelectableInput
              options={storeCategories}
              label="merchandise_categories"
              name={"+ Add Category"}
              type="category"
              selectOption={handleToggleCategories}
            />
            {submitted && merchandise_categories?.length < 1 && (
              <div className="text-primary text-xs ">
                Merchandise category is required
              </div>
            )}
          </div>
        </div>

        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
          <div
            className={
              submitted && merchandise_tags?.length < 1
                ? "  border-red-500"
                : ""
            }
          >
            <label
              htmlFor="merchandise_tags"
              className="block text-sm font-medium text-gray-700"
            >
              Merchandise Tags
            </label>
            <div className="mt-2 ">
              <SelectableInput
                options={storeTags}
                label="merchandise_tags"
                name={"+ Add Tag"}
                type="tag"
                selectOption={handleToggleTags}
              />
              {submitted && merchandise_tags?.length < 1 && (
                <div className="text-primary text-xs ">
                  Merchandise tag is required
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 items-center">
          <div>
            <h3 className="text-lg font-medium">
              Merchandise Images (200px by 200px)
            </h3>
            <p className="text-sm">
              Accepts .jpg, .png and .jpeg image formats
            </p>
          </div>
        </div>
        <div className="mt-3 mx-auto text-center bg-[#FFFBFA] p-5 border border-[#E4E4F3] rounded-md cursor-pointer">
          {imageUrls?.length > 0 ? (
            imageUrls?.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  className="w-[160px] h-[120px] "
                  alt="merchandise"
                />
              </div>
            ))
          ) : (
            <div>
              <UploadIcon className="w-8 h-8 mx-auto mb-3" />
              <p className="text-[#676565]" onClick={handleFileClick}>
                Click here to select a photo
              </p>
              <input
                type="file"
                className="hidden "
                accept="image/*"
                onChange={onImageChange}
                ref={hiddenFileInput}
              />
            </div>
          )}
        </div>
        {/* <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-8">
          {imageUrls?.length > 0 &&
            imageUrls?.map((image, index) => (
              <figure className="w-full h-[160px] relative" key={index}>
                <img
                  src={image}
                  className="object-cover w-full h-full"
                  alt="food"
                />
                <button className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2">
                  <XIcon className="h-5 w-5 text-primary mx-auto" />
                </button>
              </figure>
            ))}
        </div> */}
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8 mb-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Merchandise Attributes</h3>
          {displayArr?.length > 0 && (
            <button
              type="button"
              className="text-primary text-lg cursor-pointer"
              onClick={openModal}
            >
              + Add Attributes
            </button>
          )}
        </div>
        <AddMerchVariationModal
          show={isOpen}
          onClose={closeModal}
          selected={selected}
          setSelected={setSelected}
          selectedArr={selectedArr}
          setSelectedArr={setSelectedArr}
        />
        {displayArr?.length > 0 ? (
          displayArr?.map((attr, i) => (
            <div key={i} className="border-b border-[#E4E4F3] mt-8">
              <p className="text-md my-6">
                Title : <span className="font-medium">{attr.title}</span>
              </p>
              <div className="grid grid-cols-3 gap-24 px-4">
                <p className="py-2 text-md">Name</p>
                <p className="py-2 text-md">Value</p>
                <p className="py-2 text-md">Action</p>
              </div>
              {attr?.attributes?.map((attribute, i) => (
                <div className="grid grid-cols-3 gap-24 px-4">
                  <p className="py-2 font-medium">{attribute.name}</p>
                  <p className="py-2 font-medium">NGN {attribute.amount}</p>
                  <div>
                    <div className="flex justify-between max-w-[26px] py-2">
                      {/* <EditStoreAttribute
                        myAttributes={attribute}
                        id={attribute.id}
                      />
                      <p className="mx-4"></p> */}
                      <RemoveMerchAttributeModal
                        id={attribute?.id}
                        myAttributes={attribute}
                        selected={selected}
                        setSelected={setSelected}
                        selectedArr={selectedArr}
                        setSelectedArr={setSelectedArr}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="text-center mx-auto max-w-2xl py-8">
            <h4 className="text-2xl font-medium mb-1">
              You have no merchandise Attributes yet
            </h4>
            <p className="text-lg">
              When you create new merchandise Attributes, it will be dispalyed
              here
            </p>
            <p
              className="text-primary text-lg cursor-pointer"
              onClick={openModal}
            >
              + Add Attributes
            </p>
          </div>
        )}
      </div>
    </>
  );
}
