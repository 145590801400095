import errorHandler from "../../handlers/errorHandler";
// import successHandler from "../../handlers/successHandler";
import { scoutService } from "../../services/scout.service";
import scoutType from "./scoutTypes";


/**
 * Edit Shipper
 * @param {object} payLoad {search:"ogwugo"}
 * @returns {void}
 * */
export const searchTheApplication = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: scoutType["LOADING_SEARCH"],
      payLoad: true,
    });
    const response = await scoutService.searchApplication({
      ...payLoad,
    });
    dispatch({
      type: scoutType["LOADING_SEARCH"],
      payLoad: false,
    });
    dispatch({
      type: scoutType["SEARCH_APPLICATION"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: scoutType["LOADING_SEARCH"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
