import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Mixpanel } from "../../../plugins/mixpanel";
import { history } from "../../../helpers/history";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { Beat } from "../../../plugins/spinners.plugin";
import { getUserFromCookies } from "../../../utils/Auth";
import { loginUserAction } from "../../../redux/auth/authActions";

export default function LoginForm({ formWizardRef, props }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(loginUserAction(input));
      // check if this login is from checkout user or not
      if (response) {
        const user = getUserFromCookies();
        Mixpanel.identify(user.id);
        Mixpanel.track("Successful login");
        Mixpanel.people.set({
          $name: user?.name,
          $email: user?.email,
        });
        if (props?.type === "checkout") {
          // this is checkout
          props.onClose();
          window.location.reload();
        } else {
          // this is not checkout
          if (user?.team_roles?.length === 0) {
            history("/user/home");
          } else {
            formWizardRef.current.goTo(3);
          }
        }
      } else {
        // this is no response
        props.onClose();
        Mixpanel.track("Unsuccessful login");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={(e) => handleSubmit(e)} data-test="loginViaEmail">
      <div className="mt-10">
        <Input.Label
          title={"Email"}
          type={"email"}
          name="email"
          id="email"
          className="mt-3 h-12 py-4 rounded-lg px-2 border border-gray-300 focus:border-primary focus:outline-none sm:text-md w-full"
          placeholder="Enter email address"
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className="mt-6">
        <Input.Password
          title={"Password"}
          name="password"
          id="password"
          className="mt-3 h-12 py-4 rounded-lg px-2 border border-gray-300 focus:border-primary focus:outline-none sm:text-md w-full"
          placeholder="Enter Password"
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="text-sm font-bold text-primary mt-3 flex justify-end">
        <p
          className="cursor-pointer"
          onClick={() => {
            formWizardRef.current.goTo(2);
          }}
        >
          Forgot password?
        </p>
      </div>

      <div className="mt-6">
        <Button.Primary
          title={isLoading ? <Beat color={"#ffffff"} /> : "Log In"}
          type="submit"
          className="mt-5 mb-6 w-full h-12 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500  sm:mt-6 sm:text-sm"
          disabled={isLoading}
        />
      </div>
    </form>
  );
}
