import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Button from "../../components/buttons/buttons";
import Container from "../../components/container/container";
import FooterCommon from "../../components/footer/FooterCommon";
import { SignUpModal } from "../../components/modals/auth/signUpModal";
import { Menu, Transition } from "@headlessui/react";
import AppStore from "../../assets/images/icons/app-store.svg";
import PlayStore from "../../assets/images/icons/play-store.svg";
import Faces from "../../assets/images/pictures/faces.png";
import HeroImage from "../../assets/images/pictures/landing-hero.jpg";
import Logo from "../../assets/images/icons/ogwugo-logo.svg";
import Mobile from "../../assets/images/icons/mobile-1.png";
import HowTo from "../../assets/images/pictures/landing-other1.png";
import Rider from "../../assets/images/pictures/bike-man.png";
import Merchant from "../../assets/images/pictures/merchant.jpeg";
import ORDERS from "../../assets/images/icons/orders.svg";
import PROFILE from "../../assets/images/icons/profile.svg";
import { PIMAGE } from "../../assets/images/pictures/images";
import { ReactComponent as Clock } from "../../assets/images/icons/clock.svg";
import { ReactComponent as CustomerCare } from "../../assets/images/icons/customer-care.svg";
import { ReactComponent as Whatsapp } from "../../assets/images/icons/whatsapp.svg";
import { isAuthenticatedUser, getUserFromCookies } from "../../utils/Auth";
import { logoutUserLocallyAction } from "../../redux/auth/unAuthActions";

const userNavigation = [
  {
    name: "Profile",
    href: "/profile/info",
    image: <img src={PROFILE} alt="icon" />,
  },
  {
    name: "Orders",
    href: "/profile/orders",
    image: <img src={ORDERS} alt="icon" />,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function LandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = isAuthenticatedUser();
  const user = getUserFromCookies();
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  function logoutUser() {
    dispatch(logoutUserLocallyAction());
  }

  function capitalize(word) {
    const words = word.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  }

  function getInitials(word) {
    const words = word.split(" ");
    return words.map((word) => {
      return word[0].toUpperCase();
    });
  }

  return (
    <>
      <div data-test="landingPage">
        <div
          className="bg-white bg-top sm:bg-cover white-overlay"
          style={{ backgroundImage: `url('${HeroImage}')` }}
        >
          <Container>
            <div className="py-5 flex justify-between items-center">
              <a href="/">
                <img className="block h-10 w-auto" src={Logo} alt="Ogwugo" />
              </a>
              <div className="flex items-center gap-4">
                <Button.Secondary
                  title={"Order Now"}
                  onClick={() => navigate("/user/home")}
                  className="hidden sm:block px-3 w-[120px]"
                />

                {isAuth ? (
                  <Menu
                    as="div"
                    className="hidden sm:block flex-shrink-0 relative ml-5"
                  >
                    {/* Profile dropdown */}

                    <Menu.Button className="bg-white rounded-full flex focus:outline-none items-center">
                      {user.picture ? (
                        <img
                          src={PIMAGE}
                          className="w-8 h-8 mr-1 rounded-full"
                          alt="user"
                        />
                      ) : (
                        <div className="w-[34px] h-[34px] rounded-full text-md bg-gray-200 flex items-center justify-center mr-1">
                          {getInitials(user?.name)}
                        </div>
                      )}

                      <span>{capitalize(user?.name)}</span>
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block py-2 px-4 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                        <button
                          className="block py-2 px-4 text-sm text-gray-700"
                          onClick={logoutUser}
                        >
                          Sign out
                        </button>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <Button.Primary
                    title={"Login"}
                    className="px-3 w-[120px]"
                    onClick={openModal}
                  />
                )}
                {!isAuth && <SignUpModal show={isOpen} onClose={closeModal} />}
              </div>
            </div>
            <div className="flex justify-between py-16">
              <div className="md:text-left sm:mt-16 sm:max-w-[480px]">
                <h1 className="text-5xl sm:text-[80px] leading-[50px] sm:leading-[87px] font-bold mt-8 mb-10">
                  Order food to your door
                </h1>
                <p className="text-base md:text-2xl font-normal mb-6">
                  Experience prompt delivery of your favourite meals from the
                  best stores.
                </p>

                <Button.Primary
                  title="Order Now"
                  className={
                    "w-[200px] h-[52px] px-4 mt-[29px] text-lg font-bold"
                  }
                  onClick={() => navigate("/user/home")}
                />

                <div className="mt-8 mb-10 flex gap-2">
                  <img src={Faces} alt="faces" />
                  <div>
                    <h3>Our happy customers</h3>
                    <p className="text-sm">20k+ customers</p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <Container>
          <div className="max-w-[920px] mx-auto mt-20">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-10">
              <div className="bg-white shadow-md rounded-lg py-5 px-4 text-center">
                <div className="h-[60px] w-[60px] rounded-full bg-[#FAE9E5] flex items-center justify-center mx-auto mb-6">
                  <Clock className="h-8 w-8" />
                </div>

                <h4 className="mb-1 text-xl font-bold">9:00 am - 5:00 pm</h4>
                <p>Working hours</p>
              </div>
              <div className="bg-white shadow-md rounded-lg py-5 px-4 text-center">
                <div className="h-[60px] w-[60px] rounded-full bg-[#FAE9E5] flex items-center justify-center mx-auto mb-6">
                  <CustomerCare className="h-8 w-8" />
                </div>
                <a
                  href="http://wa.me/234814496578"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <h4 className="mb-1 text-xl font-bold">+234 814 001 9523</h4>
                </a>
                <p>Customer Service Line</p>
              </div>
              <div className="bg-white shadow-md rounded-lg py-5 px-4 text-center">
                <div className="h-[60px] w-[60px] rounded-full bg-[#FAE9E5] flex items-center justify-center mx-auto mb-6">
                  <Whatsapp className="h-8 w-8" />
                </div>
                <a
                  href="http://wa.me/2348140019523"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <h4 className="mb-1 text-xl font-bold">+234 819 990 6578</h4>
                </a>

                <p>WhatsApp online</p>
              </div>
            </div>
          </div>
        </Container>

        <Container>
          <div className="grid grid-cols-1 sm:grid-cols-2 items-center relative before:absolute before:bg-[#FFF7F5] before:h-4/5 before:w-full before:-z-10 before:rounded-2xl mt-32">
            <figure>
              <img src={Mobile} alt="mobile" />
            </figure>
            <div className="max-w-[400px] px-4 sm:px-0 py-8">
              <h3 className="text-3xl font-bold mb-3">
                A simple way to order your meals faster.
              </h3>
              <p className="text-mb mb-12">
                The food you love and more to be delivered right where you need
                them and with extreme safety.
              </p>
              <div className="flex gap-6">
                <a
                  href="https://play.google.com/store/apps/details?id=com.ogwugo.ogwugofood&hl=en&gl=US"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img src={PlayStore} alt="playstore" />
                </a>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://apps.apple.com/ng/app/ogwugo/id1572959997"
                >
                  <img src={AppStore} alt="appstore" />
                </a>
              </div>
            </div>
          </div>
        </Container>

        <Container>
          <div className="mt-32 pt-16 mx-auto max-w-[950px]">
            <div className="text-center mb-20 mx-auto max-w-[520px]">
              <p className="text-primary font-bold">Work</p>
              <h3 className="text-3xl font-bold mb-3">How It Works</h3>
              <p>
                The food you love and more to be delivered right where you need
                them and with extreme safety.
              </p>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 items-center relative before:absolute before:bg-[#FFF7F5] before:h-full before:w-full sm:before:w-3/4 before:-z-10 before:rounded-2xl">
              <ul className="px-10 py-20">
                <li className="mb-12">
                  <h3 className="mb-2 text-xl font-bold">
                    <span className="text-primary mr-2">01</span>Choose your
                    Meals
                  </h3>
                  <p>
                    Choose from over 50 restaurants within over 20 categories
                    and ranges of food. Buy food for today or a package for the
                    week.
                  </p>
                </li>
                <li className="mb-12">
                  <h3 className="mb-2 text-xl font-bold">
                    <span className="text-primary mr-2">02</span>Pay for your
                    order
                  </h3>
                  <p>
                    Pay for your order using any of our payment channels or make
                    it more fun by sending your bills to your family and
                    friends.
                  </p>
                </li>
                <li className="mb-4">
                  <h3 className="mb-2 text-xl font-bold">
                    <span className="text-primary mr-2">03</span>Delivered to
                    you
                  </h3>
                  <p>
                    Sit back and relax, or track the rider on the map, your
                    delicious food is coming to you sooner than you know.{" "}
                  </p>
                </li>
              </ul>
              <div>
                <img src={HowTo} alt="food" />
              </div>
            </div>
          </div>
        </Container>

        <Container>
          <div className="mt-32 pt-16">
            <div className="text-center mb-16 mx-auto max-w-[520px]">
              <p className="text-primary font-bold">Partners</p>
              <h3 className="text-3xl font-bold mb-3">Partner With Us</h3>
              <p>
                The food you love and more to be delivered right where you need
                them and with extreme safety.
              </p>
            </div>
            <div className="grid sm:grid-cols-2 gap-10">
              <div className="shadow-md border p-8 rounded-lg grid grid-cols-1 sm:grid-cols-2 items-center gap-8">
                <div className="order-2 sm:order-1">
                  <h3 className="text-2xl font-bold mb-3">Join O-Pilots</h3>
                  <p className="mb-5">
                    Make a monthly income while enjoying flexible working hours
                    and huge incentives when you join our team of dispatch
                    riders.
                  </p>
                  <a
                    href="https://api.whatsapp.com/message/H2DWHXYKIF5KI1?autoload=1&app_absent=0"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <Button.Primary title={"Join Now"} />
                  </a>
                </div>
                <div className="order-1 sm:order-2">
                  <img
                    src={Rider}
                    alt="o-pilot"
                    className="h-[250px] object-cover"
                  />
                </div>
              </div>
              <div className="shadow-md border p-8 rounded-lg grid grid-cols-1 sm:grid-cols-2 items-center gap-8">
                <div className="order-2 sm:order-1">
                  <h3 className="text-2xl font-bold mb-3">Join Merchants</h3>
                  <p className="mb-5">
                    As a merchant, you can leverage our massive customer base to
                    quickly and smoothly sell your products. Sign up with us
                    today!
                  </p>
                  <Button.Primary title={"Join Now"} onClick={openModal} />
                </div>
                <div className="order-1 sm:order-2">
                  <img
                    src={Merchant}
                    alt="merchant"
                    className="h-[250px] object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>

        <FooterCommon />
      </div>
    </>
  );
}

export default LandingPage;
