import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CategorySlider, {
  CategoryNav,
} from "../../../components/slider/CategorySlider";
import Container from "../../../components/container/container";
import Card from "../../../components/cards/cards";
import Button from "../../../components/buttons/buttons";
import { FilterStoreModal } from "../../../components/modals/filterStoreModal";
import { cleanInput } from "../../../helpers/cleanInput";
import FilterStore from "../filter/store";
import EmptyStore from "../../../assets/images/icons/outletEmpty.svg";
import Empty from "../../../assets/images/pictures/listingPlaceHolder2.png";
import StoreSkeleton from "./../../../components/skeleton/store";
import StoreCategoriesSkeleton from "./../../../components/skeleton/storeCategories";
import { Beat } from "../../../plugins/spinners.plugin";
import { getOutletCategories } from "../../../redux/productCatergory/productCategoryAction";
import {
  getAllPublicStore,
  getMorePublicStore,
} from "./../../../redux/publicStore/publicStoreAction";
import {
  filterPublicStore,
  filterMorePublicStore,
} from "./../../../redux/publicStore/publicStoreAction";
import { showApplication } from "../../../redux/applicationSettings/applicationAction";

export default function StoreList() {
  const dispatch = useDispatch();
  const categoryRef = useRef(null);

  const { allCategories, loading } = useSelector(
    (state) => state.productCatergory
  );
  const { allPublicStores, isLoading, loadingMore } = useSelector(
    (state) => state.publicStore
  );
  const { settings } = useSelector((state) => state.application);

  const user_city = localStorage.getItem("ogwugo-user-city");

  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [filterCount, setFilterCount] = useState(0);

  const addImageFallback = (event) => {
    event.currentTarget.src = Empty;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function getStoreCategories() {
    let items = allCategories?.data?.filter((item) => item.type === "store");
    setCategories(items);
  }

  useEffect(() => {
    dispatch(showApplication());
    // dispatch(getAllPublicStore(page));
    dispatch(getOutletCategories(1));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getStoreCategories();
    // eslint-disable-next-line
  }, [allCategories]);

  useEffect(() => {
    if (user_city !== null) {
      dispatch(filterPublicStore({ store_city: user_city }, page));
    } else {
      dispatch(getAllPublicStore(page));
    }

    //eslint-disable-next-line
  }, [user_city]);

  const handleFilter = (data) => {
    cleanInput(data);
    dispatch(filterPublicStore(data));
  };

  const loadMore = async () => {
    let newPage = Number(page) + 1;
    setPage(newPage);

    if (user_city !== null) {
      await dispatch(
        filterMorePublicStore({ restaurant_city: user_city }, newPage)
      );
    } else {
      await dispatch(getMorePublicStore(newPage));
    }
  };

  return (
    <div className="StoreList" data-test="storeList">
      <Container>
        <div className="py-5 mt-12">
          <div className="flex gap-4 justify-between items-center mb-16">
            <h3 className="text-3xl lg:text-4xl font-bold">Stores </h3>
            <div className="flex items-center gap-4">
              <span className="hidden sm:block">
                Sort by: <span className="text-primary">All</span>
              </span>
              <div className="w-[48px] h-[48px] bg-[#FFEAE5] rounded items-center justify-center flex relative">
                <span
                  className={`${
                    filterCount > 0 ? "block" : "hidden"
                  } h-4 w-4 rounded-full bg-black text-white absolute -top-1 -right-2 text-center text-xs`}
                >
                  {filterCount}
                </span>
                <FilterStoreModal
                  categories={categories}
                  handleFilter={handleFilter}
                  setFilterCount={setFilterCount}
                />
              </div>
            </div>
          </div>
          {loading && <StoreCategoriesSkeleton />}
          {!loading && filterCount === 0 && categories?.length > 0 && (
            <div>
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-bold">Categories</h3>
                <div className="flex items-center gap-4">
                  <CategoryNav categoryRef={categoryRef} />
                </div>
              </div>
              <CategorySlider categoryRef={categoryRef}>
                {categories?.map((category, index) => (
                  <Card.StoreCategories
                    category={category}
                    image={category?.picture}
                    key={index}
                    imageFallBack={addImageFallback}
                  />
                ))}
              </CategorySlider>
            </div>
          )}
        </div>
      </Container>

      <Container>
        <div className="py-5 sm:mt-12">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-2xl font-bold">All Stores</h3>
          </div>
          {isLoading && <StoreSkeleton />}
          {!isLoading && (
            <>
              {filterCount === 0 ? (
                <>
                  {allPublicStores?.data?.length > 0 ? (
                    <>
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                        {allPublicStores?.data?.length > 0 &&
                          allPublicStores?.data?.map((store, i) => (
                            <Card.Store
                              key={i}
                              image={store?.store_pictures}
                              title={store?.store_name}
                              id={store?.id}
                              tags={store?.store_tags}
                              categories={store?.store_categories}
                              imageFallBack={addImageFallback}
                              operating_settings={
                                settings?.store_operating_status
                              }
                              operating_status={store?.store_operating_status}
                              operating_hours={store?.store_operating_time}
                            />
                          ))}
                      </div>
                      {/* {loadingMore && <StoreSkeleton />} */}
                      <div className="mt-24 mb-10 text-center">
                        <p className="mb-4">
                          You’ve viewed{" "}
                          <span className="font-bold">
                            {allPublicStores?.data?.length}
                          </span>{" "}
                          out of {allPublicStores?.total} results
                        </p>
                        {allPublicStores?.data?.length <
                          allPublicStores?.total && (
                          <Button.Secondary
                            title={loadingMore ? <Beat /> : "Load More"}
                            disabled={loadingMore}
                            className="px-16"
                            onClick={() => loadMore()}
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="flex justify-center py-16">
                      <div className="text-center">
                        <img src={EmptyStore} alt="empty" />
                        <p className="my-6">No store found</p>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <FilterStore />
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  );
}
