import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../../components/buttons/buttons";
import { Beat } from "../../../plugins/spinners.plugin";
import Input from "../../../components/inputs/inputs";
import SelectableInput from "../../../components/inputs/SelectableInput";
import Location from "../../../components/includes/location";
import { getUserFromCookies } from "../../../utils/Auth";
import { history } from "../../../helpers/history";
import { logoutUserLocallyAction } from "../../../plugins/axios.plugin";
import { createRestaurantAction } from "../../../redux/restaurant/restaurantActions";
import { filterProductCategory } from "../../../redux/productCatergory/productCategoryAction";
import { getAllCategories } from "../../../redux/productCatergory/productCategoryAction";
import { getAllTags } from "../../../redux/productTags/productTagActions";

export default function CreateRestaurant() {
  const dispatch = useDispatch();
  const user = getUserFromCookies();
  let business_id = user?.groups && Object.keys(user?.groups)?.[0];

  const { allCategories } = useSelector((state) => state.productCatergory);
  const { allTags } = useSelector((state) => state.productTag);
  const { isLoading } = useSelector((state) => state.restaurant);
  const [submitted, setSubmitted] = useState(false);
  const [locationLoader, setLocationLoader] = useState(false);

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  const [data, setData] = useState({
    restaurant_email: "",
    restaurant_name: "",
    restaurant_address: "",
    restaurant_city: "",
    restaurant_state: "",
    restaurant_country: "",
    restaurant_categories: [],
    restaurant_tags: [],
    restaurant_description: "",
    restaurant_phone: "",
  });
  const {
    restaurant_name,
    restaurant_email,
    restaurant_address,
    restaurant_city,
    restaurant_state,
    restaurant_country,
    restaurant_description,
    restaurant_categories,
    restaurant_tags,
  } = data;

  let { restaurant_phone } = data;

  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getAllTags());
    dispatch(filterProductCategory({ type: "restaurant" }, 1));
    // eslint-disable-next-line
  }, []);
  const restaurantCategories = allCategories?.data?.filter((item) => {
    return item?.type === "restaurant";
  });
  const restaurantTags = allTags?.data?.filter((item) => {
    return item?.type === "restaurant";
  });

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "restaurant_categories") {
      setData({ ...data, restaurant_categories: [value] });
    } else {
      setData((data) => ({ ...data, [name]: value }));
    }
  };

  const handleAddTags = (value) => {
    const former_tags = [...data?.restaurant_tags];
    let new_tags = [...former_tags, value.name];
    setData({ ...data, restaurant_tags: new_tags });
  };

  const handleAddCategories = (value) => {
    const former_categories = [...data?.restaurant_categories];
    let new_categories = [...former_categories, value.name];
    setData({ ...data, restaurant_categories: new_categories });
  };

  const handlePhone = (e) => {
    let phone = e;
    restaurant_phone = phone?.slice(1);
    setData({ ...data, restaurant_phone: restaurant_phone });
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    if (
      restaurant_name &&
      restaurant_phone &&
      restaurant_address &&
      restaurant_categories &&
      restaurant_city &&
      restaurant_state &&
      restaurant_country
    ) {
      const response = await dispatch(
        createRestaurantAction({ ...data, business_id: business_id })
      );
      if (response.status === "success") {
        logoutUserLocallyAction();
        history("/success");
      }
    }
  };

  return (
    <div>
      <div
        className="pt-4 sm:pt-2 sm:flex items-center mx-auto"
        data-test="restaurantComponent"
      >
        <div className=" max-w-4xl mx-auto mt-10">
          <div className="sm:w-[629px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200">
            <form name="form">
              <div className="border-b-2 py-4 mb-6">
                <p className=" text-base font-medium text-gray-800 flex justify-center ">
                  Restaurant Details
                </p>
              </div>

              <div className=" sm:px-10">
                <div
                  className={
                    "mt-4" +
                    (submitted && !restaurant_name ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Restaurant Name"}
                    htmlFor={"restaurant_name"}
                    type={"text"}
                    name={"restaurant_name"}
                    onChange={handleChange}
                    placeholder={"Enter restaurant name"}
                  />
                  {submitted && !restaurant_name && (
                    <div className="text-xs text-red-500">
                      Restaurant name is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && !restaurant_email ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Restaurant Email *"}
                    htmlFor={"restaurant_email"}
                    type={"email"}
                    name={"restaurant_email"}
                    onChange={handleChange}
                    placeholder={"Enter restaurant email address"}
                  />
                  {submitted && !restaurant_email && (
                    <div className="text-xs text-red-500">
                      Restaurant email is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && !restaurant_phone ? " border-red-500" : "")
                  }
                >
                  <Input.Phone
                    defaultCountry="NG"
                    title={"Restaurant Phone Number*"}
                    htmlFor={"restaurant_phone"}
                    value={restaurant_phone}
                    onChange={(e) => handlePhone(e)}
                  />
                  {submitted && !restaurant_phone && (
                    <div className="text-xs text-red-500">
                      Restaurant phone is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && !restaurant_address ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Restaurant Address *"}
                    htmlFor={"restaurant_address"}
                    type={"text"}
                    name={"restaurant_address"}
                    onChange={handleChange}
                    placeholder={"Enter Restaurant Address"}
                  />
                  {submitted && !restaurant_address && (
                    <div className="text-xs text-red-500">
                      Restaurant address is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "mt-4" +
                    (submitted && restaurant_description
                      ? " border-red-500"
                      : "")
                  }
                >
                  <Input.TextArea
                    title={"Restaurant Description "}
                    htmlFor={"restaurant_description"}
                    type={"text"}
                    name={"restaurant_description"}
                    onChange={handleChange}
                    placeholder={"Describe your restaurant to us"}
                  />
                </div>

                <div
                  className={
                    "mt-4" +
                    (submitted && restaurant_categories
                      ? " border-red-500"
                      : "")
                  }
                >
                  <label
                    htmlFor="restaurant_categories"
                    className="text-sm font-medium text-[#151515]"
                  >
                    Restaurant Categories*
                  </label>
                  <SelectableInput
                    options={restaurantCategories}
                    label="restaurant_categories"
                    name={"+ Add Category"}
                    type="category"
                    selectOption={handleAddCategories}
                  />
                </div>

                <div
                  className={
                    "mt-4" +
                    (submitted && restaurant_tags ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="restaurant_tags"
                    className="text-sm font-medium text-[#151515]"
                  >
                    Restaurant Tags*
                  </label>
                  <SelectableInput
                    options={restaurantTags}
                    label="restaurant_tags"
                    name={"+ Add Tag"}
                    type="tag"
                    selectOption={handleAddTags}
                  />
                </div>

                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  loadingLocation={(value) => {
                    setLocationLoader(value);
                  }}
                  countryList={(value) => {
                    setLocationList({ ...locationList, countryList: value });
                  }}
                  stateList={(value) => {
                    setLocationList({ ...locationList, stateList: value });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                />
                <div
                  className={
                    "mt-4" +
                    (submitted && !restaurant_country ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="restaurant_country"
                    className="text-sm font-medium text-[#151515]"
                  >
                    Country*
                  </label>
                  <select
                    name="restaurant_country"
                    className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFFFF] focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>Select Country</option>

                    {locationLoader ? (
                      <option>Loading...</option>
                    ) : (
                      locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })
                    )}
                  </select>

                  {submitted && !restaurant_country && (
                    <div className="text-xs text-red-500">
                      Restaurant country is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && !restaurant_state ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="restaurant_state"
                    className="text-sm font-medium text-[#151515]"
                  >
                    {" "}
                    State{" "}
                  </label>
                  <select
                    name="restaurant_state"
                    className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFFFF] focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>Select state</option>

                    {locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !restaurant_state && (
                    <div className="text-xs text-red-500">
                      Restaurant state is required.
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4 mb-16" +
                    (submitted && !restaurant_city ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="restaurant_city"
                    className="text-sm font-medium text-[#151515]"
                  >
                    City*
                  </label>
                  <select
                    name="restaurant_city"
                    className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFF]  focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] focus:outline-none px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>Select a city</option>
                    {locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({ name: item.name, id: item.id })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !restaurant_city && (
                    <div className="text-xs text-red-500">
                      Restaurant city is required
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>

          <Button.Primary
            title={isLoading ? <Beat color={"#ffffff"} /> : "Submit"}
            className={"w-[333px] sm:mt-10 mx-auto flex justify-center"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
