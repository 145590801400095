import React from "react";
import INSTA from "../../../../assets/images/icons/newInsta.svg";

import WHATSAPP from "../../../../assets/images/icons/whatsapp.svg";
import EMAIL from "../../../../assets/images/icons/customer-care.svg";

import TWITTER from "../../../../assets/images/icons/twitterOrange.svg";
import FACEBOOK from "../../../../assets/images/icons/newFacebook.svg";
import OGWUGO from "../../../../assets/images/icons/ogwugo-black-logo.svg";

function HelpCenter() {
  return (
    <div data-test="helpCenter-page">
      <div className="hidden sm:block bg-white h-[906px] px-6 py-3 mb-6">
        <p className=" text-2xl text-[#2B3674] my-4 capitalize font-bold">
          Help Center
        </p>
        <p className="text-[#A3AED0] max-w-3xl  my-1">
          Looking to learn more about our services?
          <br></br>
          <span className="my-4">
            Let’s talk! If you have got questions about our delivery locations
            and pricing, how you can become a vendor with us, or how you can
            register as a dispatch rider, we would be happy to help you.
          </span>
        </p>

        <div
          className="sm:mt-16 justify-center items-center mx-auto"
          data-test="contactPage"
        >
          <div>
            <div className="max-w-3xl">
              <a
                href="tel:+2348140019523"
                className="text-base text-start text-[#2b3674]"
              >
                <div className="py-3 bg-white px-10 cursor-pointer shadow-lg rounded-lg h-[91px] sm:py-4 mb-6">
                  <div className="flex">
                    <img src={EMAIL} alt="email" />
                    <div className="ml-5">
                      <p className="text-sm text-[#A3AED0] font-meduim">
                        Call us
                      </p>
                      <span>Customer Service</span>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href=" https://wa.me/message/FSYIRWHUMOYEJ1"
                target={"_blank"}
                className="text-base text-start text-[#2b3674]"
              >
                <div className="py-3 bg-white px-10 shadow-lg rounded-lg h-[91px] sm:py-4 mb-6">
                  <div className="flex items-center justify-between ">
                    <div className="flex">
                      <img src={WHATSAPP} alt="Whatsapp" />
                      <div className="ml-5 ">
                        <p className="text-sm text-[#A3AED0] font-meduim">
                          Contact us on whatsapp
                        </p>
                        <span>Whatsapp</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="https://twitter.com/ogwugofood?lang=en"
                target={"_blank"}
                className="text-base text-start text-[#2b3674]"
                title="twitter"
                rel="noopener noreferrer"
              >
                <div className="py-3 bg-white px-10 shadow-lg rounded-lg h-[91px] sm:py-4 mb-6">
                  <div className="flex items-center justify-between">
                    <div className="flex">
                      <img src={TWITTER} alt="phone" />
                      <div className="ml-5 ">
                        <p className="text-sm text-[#A3AED0] font-meduim">
                          Contact us on Twitter
                        </p>
                        <span>Twitter</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>

              <a
                href="https://www.instagram.com/ogwugofood/?hl=en"
                target="_blank"
                title="instagram"
                rel="noopener noreferrer"
                className="text-base text-start text-[#2b3674]"
              >
                <div className="py-3 bg-white px-10 shadow-lg rounded-lg h-[91px] sm:py-4 mb-6">
                  <div className="flex items-center justify-between">
                    <div className="flex">
                      <img src={INSTA} alt="Instagram" />
                      <div className="ml-5 text-left ">
                        <p className="text-sm text-[#A3AED0] font-meduim">
                          Contact us on Instagram
                        </p>
                        <span>Instagram</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="https://web.facebook.com/ogwugofood/?_rdc=1&_rdr"
                target="_blank"
                rel="noopener noreferrer"
                title="facebook"
                className="text-base text-start text-[#2b3674]"
              >
                <div className="py-3 bg-white px-10 shadow-lg rounded-lg h-[91px] sm:py-4 mb-6">
                  <div className="flex items-center justify-between">
                    <div className="flex">
                      <img src={FACEBOOK} alt="phone" />
                      <div className="ml-5 ">
                        <p className="text-sm text-[#A3AED0] font-meduim">
                          Find us on Facebook
                        </p>

                        <span>Facebook</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className=" hidden  sm:flex justify-center mx-auto">
        <img src={OGWUGO} alt="black logo" />
      </div>
      <div className="sm:hidden">
        <div className="sm:hidden w-full bg-white pt-4 rounded-sm ">
          <p className="text-center text-sm text-gray-800">
            Hello, How can we help you?
          </p>
        </div>{" "}
        <div className=" p-5 sm:w-[500PX] max-w-lg bg-white rounded-sm  shadow-xl sm:p-8 mx-auto">
          <div className="flow-root">
            <ul className="divide-y divide-gray-200">
              <li className="py-3 sm:py-4 cursor-pointer"></li>
              <li className="py-3 sm:py-4">
                <div className="flex items-center justify-between">
                  <div className="flex">
                    <img src={EMAIL} alt="email" />
                    <div className="ml-8 ">
                      <a
                        href="tel:+2348140019523"
                        target={"_blank"}
                        rel="noopener noreferrer"
                        className="text-lg text-start font-medium text-gray-900 truncate"
                      >
                        Customer care
                      </a>
                    </div>
                  </div>

                  <div className="inline-flex items-center text-base font-semibold text-gray-900"></div>
                </div>
              </li>
              <li className="py-3 sm:py-4">
                <div className="flex items-center justify-between ">
                  <div className="flex">
                    <img src={WHATSAPP} alt="Whatsapp" />
                    <div className="ml-7 ">
                      <a
                        href=" https://wa.me/message/FSYIRWHUMOYEJ1"
                        target={"_blank"}
                        className="text-lg text-start font-medium text-gray-900 truncate"
                      >
                        Whatsapp
                      </a>
                    </div>
                  </div>

                  <div className="inline-flex items-center text-base font-semibold text-gray-900"></div>
                </div>
              </li>
              <li className="py-3 sm:py-4">
                <div className="flex items-center justify-between">
                  <div className="flex">
                    <img src={TWITTER} alt="phone" />
                    <div className="ml-8 ">
                      <a
                        href="https://twitter.com/ogwugofood?lang=en"
                        target={"_blank"}
                        className="text-lg font-medium text-gray-900"
                        title="twitter"
                        rel="noopener noreferrer"
                      >
                        Twitter
                      </a>
                    </div>
                  </div>

                  <div className="inline-flex items-center text-base font-semibold text-gray-900"></div>
                </div>
              </li>
              <li className="py-3 sm:py-4">
                <div className="flex items-center justify-between">
                  <div className="flex">
                    <img src={INSTA} alt="Instagram" />
                    <div className="ml-8 text-left ">
                      <a
                        href="https://www.instagram.com/ogwugofood/?hl=en"
                        target="_blank"
                        title="instagram"
                        rel="noopener noreferrer"
                        className="text-lg font-medium text-gray-900 truncate"
                      >
                        Instagram
                      </a>
                    </div>
                  </div>

                  <div className="inline-flex items-center text-base font-semibold text-gray-900"></div>
                </div>
              </li>

              <li className="py-3 sm:py-4">
                <div className="flex items-center justify-between">
                  <div className="flex">
                    <img src={FACEBOOK} alt="phone" />
                    <div className="ml-8 ">
                      <a
                        href="https://web.facebook.com/ogwugofood/?_rdc=1&_rdr"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="facebook"
                        className="text-lg font-medium text-gray-900"
                      >
                        Facebook
                      </a>
                    </div>
                  </div>

                  <div className="inline-flex items-center text-base font-semibold text-gray-900"></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpCenter;
