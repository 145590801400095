import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { shipperService } from "./../../services/shippers.service";
import shipperType from "./shippersTypes";

/**
 * GetAllShippers
 *
 */
export const getAllShippers = (page) => async (dispatch) => {
  try {
    dispatch({
      type: shipperType["LOADING_SHIPPERS"],
      payLoad: true,
    });
    const response = await shipperService.getAllShippers(page);
    dispatch({
      type: shipperType["LOADING_SHIPPERS"],
      payLoad: false,
    });
    dispatch({
      type: shipperType["GET_ALL_SHIPPERS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: shipperType["LOADING_SHIPPERS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * StoreShippers
 * @param {object} payLoad{ name:"gaming",types: skilled}
 * @returns {void}
 * */
export const createShipper = (payLoad) => async (dispatch) => {
  dispatch({
    type: shipperType["CREATING"],
    payLoad: true,
  });
  try {
    const response = await shipperService.storeShipper({
      ...payLoad,
    });
    dispatch({
      type: shipperType["CREATING"],
      payLoad: false,
    });
    dispatch({
      type: shipperType["STORE_SHIPPERS"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: shipperType["CREATING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Delete Shipper
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const deleteShipper = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: shipperType["DELETING"],
      payLoad: true,
    });
    const response = await shipperService.deleteShipper({
      id: payLoad,
    });
    dispatch({
      type: shipperType["DELETING"],
      payLoad: false,
    });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({
      type: shipperType["DELETING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Edit Shipper
 * @param {object} payLoad {id:"22"}
 * @returns {void}
 * */
export const editShipper = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: shipperType["UPDATING"],
      payLoad: true,
    });
    const response = await shipperService.editShipper({
      ...payLoad,
    });

    dispatch({
      type: shipperType["UPDATING"],
      payLoad: false,
    });
    dispatch({
      type: shipperType["EDIT_SHIPPERS"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: shipperType["UPDATING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
/**
 * Edit Shipper
 * @param {object} payLoad {search:"ogwugo"}
 * @returns {void}
 * */
export const searchShippers = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: shipperType["LOADING_SEARCH"],
      payLoad: true,
    });
    const response = await shipperService.searchShipper({
      ...payLoad,
    });

    dispatch({
      type: shipperType["LOADING_SEARCH"],
      payLoad: false,
    });
    dispatch({
      type: shipperType["SEARCH_SHIPPERS"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: shipperType["LOADING_SEARCH"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
/**
 * Edit Shipper
 * @param {object} payLoad {company_id :"7b3e58e0-b68c-498d-b8d5-82198ef6d481"}
 * @returns {void}
 * */
export const filterShippers = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({
      type: shipperType["FILTERING"],
      payLoad: true,
    });
    const response = await shipperService.filterShipper(payLoad, page);
    dispatch({
      type: shipperType["FILTERING"],
      payLoad: false,
    });
    dispatch({
      type: shipperType["FILTER_SHIPPERS"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: shipperType["FILTERING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

export const filterShippersNoPage = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: shipperType["FILTERING"],
      payLoad: true,
    });
    const response = await shipperService.filterShipperNoPage({
      ...payLoad,
    });
    dispatch({
      type: shipperType["FILTERING"],
      payLoad: false,
    });
    dispatch({
      type: shipperType["FILTER_SHIPPERS_NOPAGE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: shipperType["FILTERING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
/**
 * Show Shipper
 * @param {object} payLoad {properties:1}
 * */
export const showShipper = (payLoad) => async (dispatch) => {
  try {
    const response = await shipperService.showAShipper(payLoad);

    dispatch({
      type: shipperType["LOADING_SHIPPERS"],
      payLoad: false,
    });
    dispatch({
      type: shipperType["SHOW_SHIPPERS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: shipperType["LOADING_SHIPPERS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
/**
 * Show my Shipper
 * @param {object} payLoad {properties:1}
 * */
export const showMyShipper = () => async (dispatch) => {
  try {
    dispatch({
      type: shipperType["LOADING_SHIPPERS"],
      payLoad: false,
    });
    const response = await shipperService.showMyShipper();

    dispatch({
      type: shipperType["SHOW_MY_SHIPPERS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: shipperType["LOADING_SHIPPERS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
export const verifyShipper = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: shipperType["VERIFYING"], payLoad: true });
    const response = await shipperService.approveBusiness(payLoad);
    dispatch({ type: shipperType["VERIFYING"], payLoad: false });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: shipperType["VERIFYING"], payLoad: false });
    errorHandler(error, true);
  }
};
export const unverifyShipper = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: shipperType["VERIFYING"], payLoad: true });
    const response = await shipperService.unapproveBusiness(payLoad);
    dispatch({ type: shipperType["VERIFYING"], payLoad: false });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: shipperType["VERIFYING"], payLoad: false });
    errorHandler(error, true);
  }
};
export const approveShipper = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: shipperType["APPROVING"], payLoad: true });
    const response = await shipperService.approveShipper(payLoad);
    dispatch({ type: shipperType["APPROVING"], payLoad: false });
    dispatch({ type: shipperType["APPROVE_SHIPPER"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: shipperType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};
export const unapproveShipper = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: shipperType["APPROVING"], payLoad: true });
    const response = await shipperService.unapproveShipper(payLoad);
    dispatch({ type: shipperType["UNAPPROVE_SHIPPER"], payLoad: false });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: shipperType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};
