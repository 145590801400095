import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../../plugins/spinners.plugin";
import Button from "../../../../components/buttons/buttons";
import Logo from "../../../../assets/images/icons/ogwugo-black-logo.svg";
import MINUS from "../../../../assets/images/icons/decreament-disabled.svg";
import PLUS from "../../../../assets/images/icons/increament-disabled.svg";
import EmptyState from "../../../../assets/images/emptyState/orders.svg";
import { cleanInput } from "../../../../helpers/cleanInput";
import { updateAStoreOrder } from "../../../../redux/storeOrders/storeOrderAction";

export default function RightInput({ data, setSubmitted, recipient_phone }) {
  const { order_discount, order_amount, order_sub_amount } =
    data;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [displayData, setDisplayData] = useState([]);
  const { updating } = useSelector((state) => state.storeOrder);

  useEffect(() => {
    if (data?.order_items?.length > 0) {
      setDisplayData(data?.order_items);
    }
  }, [data?.order_items]);

  const handleSubmit = async () => {
    let newData = { ...data, recipient_phone: recipient_phone };
    cleanInput(newData?.order_items?.attributes);
    cleanInput(newData);
    setSubmitted(true);

    const response = await dispatch(updateAStoreOrder(newData));
    if (response) {
      navigate(-1);
    }
  };

  return (
    <div>
      <div className="border border-[#E4E4F3] bg-white sm:min-h-[786px] sm:w-[527px] rounded-md py-5 px-8">
        <div className="flex justify-between items-start">
          <img src={Logo} alt="black logo" className="mt-6" />
        </div>

        <>
          <div className="flex justify-between mt-5">
            {data.order_payment_method && (
              <div>
                <p className="text-sm text-[#4D4D4D]">Payment Method</p>
                <p className="text-sm font-bold my-1">
                  {data.order_payment_method}
                </p>
              </div>
            )}
            {data?.order_paid && (
              <div className="text-right">
                <p className="text-sm text-[#4D4D4D]">Payment Status</p>
                <p className="text-sm font-bold my-1">
                  {data?.order_paid === "1" || data?.order_paid
                    ? "Paid"
                    : "Unpaid"}
                </p>
              </div>
            )}
          </div>
          <div className="flex justify-between my-8 ">
            {data?.recipient_name && (
              <div>
                <p className="text-sm text-[#4D4D4D]">Recipients Name </p>
                <p className="text-sm font-bold my-1">{data?.recipient_name}</p>
              </div>
            )}
            {(data?.recipient_phone || recipient_phone) && (
              <div className="text-right">
                <p className="text-sm text-[#4D4D4D]">Recipients Phone </p>
                <p className="text-sm font-bold my-1">
                  {data?.recipient_phone !== ""
                    ? data?.recipient_phone
                    : recipient_phone}
                </p>
              </div>
            )}
          </div>
        </>

        {data.order_items && data.order_items?.length > 0 ? (
          data.order_items?.map((item, index) => (
            <div
              key={index}
              className="px-6 py-2 border-b border-[#565656] border-dashed last:border-0 last:pb-20"
            >
              <div className="flex justify-between my-3">
                <p className="text-sm font-bold">
                  {displayData[index]?.food_name ||
                    displayData[index]?.product_name}
                </p>
                <p className="text-sm">
                  NGN{" "}
                  {displayData[index]?.food_amount ||
                    displayData[index]?.product_amount}
                </p>
              </div>

              {displayData[index]?.attributes?.length > 0 &&
                displayData[index].attributes?.map(
                  (attribute) =>
                    typeof attribute !== "undefined" && (
                      <div
                        className="flex justify-between"
                        key={attribute?.attribute_id}
                      >
                        <p className="text-sm font-bold">
                          {attribute?.name} x {attribute?.quantity}
                        </p>
                        <p className="text-sm ">NGN {attribute?.amount} </p>
                      </div>
                    )
                )}

              <div className="flex justify-between">
                <button
                  type="button"
                  disabled
                  className=" w-24 sm:w-[78px] inline-flex mt-2 items-center h-11 sm:h-5 text-base font-medium  sm:text-sm ml-2 sm:ml-0"
                >
                  <img
                    src={MINUS}
                    alt="decrement counter"
                    className=" w-6 h-6 sm:w-5 sm:h-5 text-[#C4C4C4]"
                  />

                  <p className="mx-2 text-sm">{item?.quantity}</p>
                  <img
                    src={PLUS}
                    alt="increment counter"
                    className=" w-6 h-6 sm:w-5 sm:h-5"
                  />
                </button>
                <button className="text-[#C4C4C4]">Remove</button>
              </div>
            </div>
          ))
        ) : (
          <div className="pt-16 pb-4 text-center">
            <div className="flex justify-center">
              <img
                src={EmptyState}
                className="w-[100px] h-[100px]"
                alt="no data"
              />
            </div>
            <h3 className="mt-5 text-xl font-bold">Restaurant Orders</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any orders yet.
            </p>
          </div>
        )}
        <div className="text-center mt-4">
          <h5 className="text-[#C4C4C4] text-sm"> Add New Item + </h5>
        </div>

        <div className="mt-10">
          <div className="flex justify-between">
            <p className="text-sm font-bold py-1">Subtotal</p>
            <p className="text-sm font-bold py-1">NGN {order_sub_amount} </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm py-1">Discount</p>
            <p className="text-sm py-1"> NGN {order_discount}</p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm font-bold py-1">Total</p>
            <p className="text-sm font-bold py-1">NGN {order_amount}</p>
          </div>
        </div>

        <div className="border mt-2" />
      </div>
      <div className="flex justify-center my-12 sm:w-[527px]">
        <Button.Primary
          disabled={updating}
          title={updating ? <Beat color={"#ffffff"} /> : "Update Order"}
          className="rounded-sm"
          type={"button"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
