import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { Catalogs } from "./Catalogs";
import errorHandler from "../../../handlers/errorHandler";
import Card from "../../../components/cards/cards";
import Container from "../../../components/container/container";
import CategoriesSkeleton from "../../../components/skeleton/categories";
import StoreSkeleton from "../../../components/skeleton/store";
import ADVERTIMG from "../../../assets/images/pictures/advertImage.jpg";
// import TodayMenu, { TodayMenuNav } from "../../../components/slider/TodayMenu";
// import RestaurantSlider, {
//   RestaurantNav,
// } from "../../../components/slider/RestaurantSlider";
import HomeSlider, { HomeNav } from "../../../components/slider/HomeSlider";
import CategorySlider, {
  CategoryNav,
} from "../../../components/slider/CategorySlider";
import { getAllPublicAdvertisments } from "../../../redux/publicAdvertisment/publicAdvertismentAction";
import { getOutletCategories } from "../../../redux/productCatergory/productCategoryAction";
import {
  getAllPublicStore,
  filterPublicStore,
} from "../../../redux/publicStore/publicStoreAction";
import {
  getAllPublicRestaurantsAction,
  filterPublicRestaurantsAction,
} from "../../../redux/publicRestaurant/publicRestaurantActions";
import { getAllPublicCataloguesAction } from "./../../../redux/publicCatalogues/publicCatalogueAction";
import { showApplication } from "../../../redux/applicationSettings/applicationAction";
import { saveUserDetails } from "../../../redux/auth/authActions";

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const advertRef = useRef(null);
  const categoryRef = useRef(null);
  const baseURL = process.env.REACT_APP_API_URL;
  const storeCategoryRef = useRef(null);
  const user_city = localStorage.getItem("ogwugo-user-city");

  const { allAdvertisments } = useSelector((state) => state.publicAdvertisment);
  const { allRestaurants } = useSelector((state) => state.publicRestaurant);
  const { allCatalogues } = useSelector((state) => state.publicCatalogue);
  const { settings } = useSelector((state) => state.application);
  const { allPublicStores, isLoading } = useSelector(
    (state) => state.publicStore
  );
  const { allCategories, loading } = useSelector(
    (state) => state.productCatergory
  );

  const [advertisment, setAdvertisment] = useState(allAdvertisments?.data);
  const [catalogue, setCatalogue] = useState(allCatalogues?.data);
  const [categories, setCategories] = useState([]);
  const [storeCategories, setStoreCategories] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const url = window.location.href.replace("#", "?");
    const searchParams = new URLSearchParams(new URL(url).search);
    const access_token = searchParams.get("access_token");
    const id_token = searchParams.get("id_token");

    // Check if google login or apple login
    let socialite_url;
    let token;

    if (access_token) {
      socialite_url = `${baseURL}/api/auth/socialite/google/callback`;
      token = access_token;
    }

    if (id_token) {
      socialite_url = `${baseURL}/api/auth/socialite/apple/callback`;
      token = id_token;
    }

    if (token) {
      axios({
        url: socialite_url,
        method: "get",
        params: {
          token: token,
        },
      })
        .then((res) => {
          saveUserDetails(res?.data);
          navigate("/user/home");
        })
        .catch((err) => {
          errorHandler(err, true);
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(showApplication());
    dispatch(getAllPublicAdvertisments());
    dispatch(getOutletCategories(1));
    dispatch(getAllPublicCataloguesAction());

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user_city !== null) {
      dispatch(filterPublicStore({ store_city: user_city }));
      dispatch(filterPublicRestaurantsAction({ restaurant_city: user_city }));
    } else {
      dispatch(getAllPublicStore());
      dispatch(getAllPublicRestaurantsAction());
    }

    //eslint-disable-next-line
  }, [user_city]);

  const addImageFallback = (event) => {
    event.currentTarget.src = ADVERTIMG;
  };
  useEffect(() => {
    setAdvertisment(allAdvertisments.data);
    // eslint-disable-next-line
  }, [allAdvertisments]);

  useEffect(() => {
    setCatalogue(allCatalogues?.data);
    // eslint-disable-next-line
  }, [allCatalogues]);

  useEffect(() => {
    let items = allCategories?.data?.filter(
      (item) => item.type === "restaurant"
    );
    let store_items = allCategories?.data?.filter(
      (item) => item.type === "store"
    );
    setCategories(items);
    setStoreCategories(store_items);
  }, [allCategories]);

  return (
    <div data-test="home-page" className="pt-8">
      {/* <Container>
        <div className="py-8 sm:py-16">
          <h2 className="text-3xl sm:text-5xl leading-[42px] sm:leading-[64px] font-bold mb-5">
            Delivery from <span className="text-primary-500">Enugu</span>’s
            <br />
            Best Stores
          </h2>

          <p className="text-xl">The meals you love, delivered with care.</p>
        </div>
      </Container> */}

      {advertisment && (
        <Container>
          <div className="mb-3 sm:mb-10 bg-white py-5 rounded-lg relative">
            {advertisment?.length > 0 && <HomeNav advertRef={advertRef} />}

            <HomeSlider advertRef={advertRef} advertisment={advertisment}>
              {advertisment?.map((advert, index) => (
                <Link to={advert?.url} key={index}>
                  <img
                    src={advert?.pictures}
                    alt="advert-img"
                    onError={addImageFallback}
                    className="w-full object-cover h-[200px] sm:h-[360px] rounded-xl cursor-pointer"
                  />
                </Link>
              ))}
            </HomeSlider>
          </div>
        </Container>
      )}

      {catalogue && catalogue.map((cat, i) => <Catalogs cat={cat} key={i} />)}

      <Container>
        {loading && <CategoriesSkeleton />}
        {!loading && categories?.length > 0 && (
          <>
            <div className="py-5 mt-0 sm:mt-8">
              <div className="flex justify-between items-center mb-6 mt-5 sm:mt-8">
                <h3 className="text-2xl font-bold">Restaurant Categories</h3>
                <div className="flex items-center gap-4">
                  {categories?.length > 6 && (
                    <CategoryNav categoryRef={categoryRef} />
                  )}
                </div>
              </div>
              <CategorySlider categoryRef={categoryRef}>
                {categories?.map((category, index) => (
                  <Card.Categories key={index} category={category} />
                ))}
              </CategorySlider>
            </div>
          </>
        )}
      </Container>

      <Container>
        {isLoading ? (
          <StoreSkeleton />
        ) : (
          <div className="py-5 mt-8">
            {allRestaurants?.data?.length > 0 && (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-2xl font-bold">All Restaurants</h3>
                  <a
                    href="/user/restaurants"
                    className="text-primary text-md font-medium"
                  >
                    See all
                  </a>
                </div>
                <div className=" grid cols-1 sm:grid-cols-4 gap-[20px]">
                  {allRestaurants?.data
                    ?.slice(0, 16)
                    ?.map((restaurant, index) => (
                      <Card.Restaurants
                        key={index}
                        id={restaurant?.id}
                        title={restaurant?.restaurant_name}
                        tags={restaurant?.restaurant_tags}
                        categories={restaurant?.restaurant_categories}
                        image={restaurant?.restaurant_pictures?.[0]}
                        operating_settings={
                          settings?.restaurant_operating_status
                        }
                        operating_status={
                          restaurant?.restaurant_operating_status
                        }
                        operating_hours={restaurant?.restaurant_operating_time}
                      />
                    ))}
                </div>
              </>
            )}
          </div>
        )}
      </Container>

      <Container>
        {loading && <CategoriesSkeleton />}
        {!loading && storeCategories?.length > 0 && (
          <>
            <div className="py-5 mt-8">
              <div className="flex justify-between items-center mb-6 mt-8">
                <h3 className="text-2xl font-bold">Store Categories</h3>
                <div className="flex items-center gap-4">
                  {storeCategories?.length > 6 && (
                    <CategoryNav categoryRef={storeCategoryRef} />
                  )}
                </div>
              </div>
              <CategorySlider categoryRef={storeCategoryRef}>
                {storeCategories?.map((category, index) => (
                  <Card.StoreCategories
                    key={index}
                    category={category}
                    image={category?.picture}
                    imageFallBack={addImageFallback}
                  />
                ))}
              </CategorySlider>
            </div>
          </>
        )}
      </Container>

      <Container>
        {isLoading ? (
          <StoreSkeleton />
        ) : (
          <div className="py-5 mt-8">
            {allPublicStores?.data?.length > 0 && (
              <>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-2xl font-bold">All Stores</h3>
                  <a
                    href="/user/stores"
                    className="text-primary text-md font-medium"
                  >
                    See all
                  </a>
                </div>
                <div className=" grid cols-1 sm:grid-cols-4 gap-[20px]">
                  {allPublicStores?.data?.slice(0, 16)?.map((store, i) => (
                    <Card.Store
                      key={i}
                      image={store?.store_pictures}
                      title={store?.store_name}
                      id={store?.id}
                      tags={store?.store_tags}
                      categories={store?.store_categories}
                      imageFallBack={addImageFallback}
                      operating_settings={settings?.store_operating_status}
                      operating_status={store?.store_operating_status}
                      operating_hours={store?.store_operating_time}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </Container>
    </div>
  );
}
