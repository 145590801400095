import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../../components/buttons/buttons";
import { ReactComponent as CopyIcon } from "../../../assets/images/icons/copy-outline.svg";
import { Assignshipping } from "../../../components/modals/assignShipper";
import { CloseOrder } from "../../../components/modals/closeOrder";
import { getSingleStoreOrder } from "../../../redux/storeOrders/storeOrderAction";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function SingleAdminOrder() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const order = location.state.order;
  const { singleStoreOrder } = useSelector((state) => state.storeOrder);

  useEffect(() => {
    if (order?.id) {
      dispatch(getSingleStoreOrder(order?.id));
    }
    // eslint-disable-next-line
  }, [order?.id]);

  //to copy text to clipboard
  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    toast.success("Copied", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  /**
   * Truncate transaction ref and add ellipsis in middle
   *
   * @param {string} string
   * @param {number} lengthToShow
   * @param {string} seperator
   * @returns {string}
   */
  const truncateText = (string, lengthToShow = 25, separator = "...") => {
    if (string?.length <= lengthToShow) return string;

    const sepLen = separator?.length;
    const charsToShow = lengthToShow - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      string?.substr(0, frontChars) +
      separator +
      string?.substr(string.length - backChars)
    );
  };

  return (
    <div data-test="singleOrder-page">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 pt-6">
        {/* Grid 1 */}
        <div>
          <div className="border border-[#E4E4F3] bg-white rounded-md pb-6 px-4 sm:px-8 py-5">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Order Details</h3>
              {(singleStoreOrder?.status === "unshipped" ||
                singleStoreOrder?.status === "shipped") && (
                <Button.Primary
                  type={"button"}
                  title={"Edit Order"}
                  onClick={() => {
                    navigate(
                      `/admin/store-orders/edit/${singleStoreOrder?.id}`
                    );
                  }}
                />
              )}
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order date
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  {moment(singleStoreOrder?.created_at).format("MMM DD, YYYY")}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Order ID</label>
                <p className="text-[#151515] text-sm font-bold flex">
                  {truncateText(singleStoreOrder?.id)}
                  <CopyIcon
                    className="h-4 w-4 ml-1"
                    onClick={() => copyTextToClipboard(order.id)}
                  />
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Amount
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_sub_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Delivery Fee
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_delivery_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Discount
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_discount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Insurance Fee
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_insurance_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Service Fee
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_service_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Total Amount
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Status
                </label>
                {singleStoreOrder?.status === "unshipped" ? (
                  <p className="text-primary text-sm font-bold">unshipped</p>
                ) : singleStoreOrder?.status === "shipped" ? (
                  <p className="text-[#046ECF] text-sm font-bold">Shipped</p>
                ) : singleStoreOrder?.status === "closed" ? (
                  <p className="text-primary text-sm font-bold">Closed</p>
                ) : singleStoreOrder?.status === "delivered" ? (
                  <p className="text-[#00D220] text-sm font-bold">Delivered</p>
                ) : (
                  ""
                )}
              </div>

              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Payment Status
                </label>
                {singleStoreOrder?.order_paid === true ? (
                  <p className="text-[#00D220] text-sm font-bold">Paid</p>
                ) : (
                  <p className="text-primary text-sm font-bold">Not Paid</p>
                )}
              </div>
              <div className="">
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Payment Method
                </label>
                <p className="text-[#1634A1] text-sm font-bold">
                  {singleStoreOrder?.order_payment_method === null
                    ? "Not Available"
                    : singleStoreOrder?.order_payment_method}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-8">
              {singleStoreOrder?.status === "unshipped" && (
                <CloseOrder order={order} />
              )}
              {singleStoreOrder?.status === "unshipped" && (
                <Assignshipping
                  order_id={singleStoreOrder?.id}
                  order_type={"store"}
                />
              )}
            </div>
          </div>

          {singleStoreOrder?.order_coupons?.length > 0 && (
            <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
              <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
                <h3 className="text-lg font-medium">Coupon Details</h3>
              </div>

              {singleStoreOrder?.order_coupons?.map((coupon, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-4 sm:gap-8 mt-6"
                >
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Slug Name
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      {coupon?.slug}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">Title</label>
                    <p className="text-[#151515] text-sm font-bold flex">
                      {coupon?.title}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Amount
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      NGN {numberWithCommas(coupon?.amount)}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Expires
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      {moment(coupon?.expiry).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {singleStoreOrder?.order_promotion_codes?.length > 0 && (
            <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
              <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
                <h3 className="text-lg font-medium">Promo Code Details</h3>
              </div>

              {singleStoreOrder?.order_promotion_codes?.map((coupon, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-4 sm:gap-8 mt-6"
                >
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Slug Name
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      {coupon?.slug}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">Title</label>
                    <p className="text-[#151515] text-sm font-bold flex">
                      {coupon?.title}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Amount
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      NGN {numberWithCommas(coupon?.amount)}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Expires
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      {moment(coupon?.expiry).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Grid 2 */}
        <div>
          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mb-5">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Store Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Store Name
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  {singleStoreOrder?.store?.store_name}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Store Email
                </label>
                <p className="text-[#151515] text-sm font-bold break-words">
                  {singleStoreOrder?.store?.store_email}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Store Phone Number
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  {singleStoreOrder?.store?.store_phone}
                </p>
              </div>
            </div>
          </div>
          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 pb-12 px-4 sm:px-8 mb-5">
            <div className="border-b border-[#E4E4F3] py-3 mb-4 flex justify-between items-center">
              <h3 className="text-lg font-medium">Product Details</h3>
            </div>

            {singleStoreOrder?.order_items?.length > 0 &&
              singleStoreOrder?.order_items?.map((product, i) => (
                <div key={i}>
                  <div className="flex justify-between items-start mb-5">
                    <div className="flex">
                      <span className="w-6 mr-3 sm:mr-10">
                        {product.quantity}x
                      </span>
                      <div>
                        <p className="mb-1">{product?.product_name}</p>
                        {product?.product_attributes?.length > 0 &&
                          product?.product_attributes?.map((attr, i) => (
                            <p key={i} className="text-sm text-[#676565]">
                              {attr?.quantity}x {attr?.name} - NGN{" "}
                              {attr?.amount}
                            </p>
                          ))}
                      </div>
                    </div>

                    <p>NGN {numberWithCommas(product?.product_amount)}</p>
                  </div>
                </div>
              ))}
          </div>
          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-6 pb-12">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Customer Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
                <p className="text-[#151515] text-sm font-bold">
                  {singleStoreOrder?.recipient_name}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
                <p className="text-[#151515] text-sm  font-bold">
                  {singleStoreOrder?.recipient_email === null
                    ? "Not Available"
                    : singleStoreOrder?.recipient_email}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Phone Number
                </label>
                <p className="text-[#151515] text-sm  font-bold">
                  {singleStoreOrder?.recipient_phone === null
                    ? "Not Available"
                    : singleStoreOrder?.recipient_phone}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
                <p className="text-[#151515] text-sm font-bold">
                  {singleStoreOrder?.recipient_address}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
