import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/buttons/buttons";
import Input from "../../../../components/inputs/inputs";
import { cleanInput } from "../../../../helpers/cleanInput";
import { Beat } from "../../../../plugins/spinners.plugin";
import { storeInvite } from "../../../../redux/invite/inviteActions";
import restaurantType from "../../../../redux/restaurant/restaurantTypes";
import { searchRestaurantsAction } from "../../../../redux/restaurant/restaurantActions";

export default function InviteRestaurantEmployee() {
  const dispatch = useDispatch();
  const { search } = useSelector((state) => state.restaurant);
  const { loading } = useSelector((state) => state.invite);

  const [input, setInput] = useState({
    invitee_email: "",
    job_title: "",
    job_description: "",
    type: "employee",
  });
  const [submitted, setSubmitted] = useState(false);
  const [restautantName, setRestautantName] = useState("");
  const [business_id, setBusinessId] = useState("");
  const { invitee_email, job_description, job_title, type } = input;

  function handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: value }));
  }

  const handleSearch = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setRestautantName(value);
    if (value.length >= 3) {
      dispatch(
        searchRestaurantsAction({
          search: value,
        })
      );
    } else if (value.length <= 3) {
      dispatch({ type: restaurantType["SEARCH_RESTAURANT"], payLoad: [] });
    }
  };

  function selectRestaurant(result) {
    setBusinessId(result.business_id);
    setRestautantName(result.restaurant_name);
    var mydiv = document.getElementById(result.id);
    if (result.id) {
      mydiv.style.borderColor = "border-[#D14522]";
    }
    dispatch({ type: restaurantType["SEARCH_RESTAURANT"], payLoad: [] });
  }

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setSubmitted(true);

    if (invitee_email && job_title && job_description && type && business_id) {
      cleanInput(input);
      // get return url from location state or default to home page
      const response = await dispatch(storeInvite(input, business_id));
      if (response) {
        setSubmitted(false);
        setInput({
          invitee_email: "",
          job_title: "",
          job_description: "",
          type: "employee",
        });
        setRestautantName("");
      }
    }
  };

  return (
    <>
      <div className="w-100 mx-auto">
        <div className="mx-auto bg-white sm:w-[630px] w-full mt-10 sm:mt-0 sm:border px-6 pb-4 sm:border-gray-200">
          <div className="border-b-2 py-4 mb-6">
            <p className=" text-base font-medium text-gray-800 flex justify-center ">
              Invite Admin Employee
            </p>
          </div>

          <form name="form" id="invite_form" onSubmit={handleSubmit}>
            <div>
              <Input.Label
                title={"Restaurant name"}
                type="text"
                name="restaurant_id"
                id="restaurant_id"
                value={restautantName}
                className="w-full border border-[#E4E4F3] bg-[#F3F3F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Search restaurant"
                onChange={handleSearch}
              />
              {submitted && !business_id && (
                <div className="text-xs text-red-500">
                  Restaurant name is required
                </div>
              )}

              {search &&
                Array.isArray(search) &&
                search?.map((result, index) => (
                  <div
                    className="p-3 border border-primary mb-2"
                    id={result.id}
                    key={index}
                    onClick={() => selectRestaurant(result)}
                  >
                    <div className=" grid-cols-3">
                      <div className="col-span-1">
                        <div className="">
                          <h6 className="text-sm font-meduim">
                            Name: {result.restaurant_name}
                          </h6>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div>
                          <h6 className="text-sm font-meduim">
                            Phone: {result.restaurant_phone}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div
              className={
                "mt-4" + (submitted && job_description ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Job Descriptions"}
                type="text"
                name="job_description"
                htmlFor={"job_description"}
                onChange={handleChange}
                autoComplete="off"
                value={job_description}
                placeholder="Enter Job description"
              />
              {submitted && job_description === "" && (
                <div className="text-primary text-xs ">
                  Job description is required
                </div>
              )}
            </div>

            <div
              className={
                "mt-4" + (submitted && job_title ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Job Title*"}
                type="text"
                name="job_title"
                htmlFor={"job_title"}
                autoComplete="off"
                value={job_title}
                placeholder="Enter Job title"
                onChange={handleChange}
              />
              {submitted && job_title === "" && (
                <div className="text-primary text-xs ">
                  Job title is required
                </div>
              )}
            </div>

            <div
              className={
                "mt-4" + (submitted && invitee_email ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Email*"}
                type="email"
                htmlFor={"invitee_email"}
                name="invitee_email"
                id="invitee_email"
                autoComplete="off"
                value={invitee_email}
                placeholder="Enter email address"
                onChange={handleChange}
              />
              {submitted && invitee_email === "" && (
                <div className="text-primary text-xs ">
                  Invitee email is required
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center mx-auto">
        <Button.Primary
          disabled={loading}
          title={loading ? <Beat color={"#ffffff"} /> : "Send Invite"}
          className={"px-4 rounded-sm sm:mt-10"}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
}
