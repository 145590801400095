import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//components
import Container from "../../../components/container/container";
import Card from "../../../components/cards/cards";
import CountdownTimer from "../../../components/countdownTimer";
import NavBarCommon from "../../../components/navigation/NavBarCommon";
//images and icons
import Banner from "../../../assets/images/pictures/track-banner.jpg";
import LEFT from "../../../assets/images/icons/arrow-left.svg";
import {
  QuestionMarkCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { ContactModal } from "../../../components/modals/contactModal";
import { RestaurantInfo } from "../../../components/modals/restaurantInfo";
import { getARestaurantOrderAction } from "../../../redux/restaurantOrder/restaurantOrderActions";

const tabs = [
  { name: "Order Status", href: "status" },
  { name: "Order Details", href: "details" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TrackOrder() {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  let [isVisible, setIsVisible] = useState(false);

  function close() {
    setIsVisible(false);
  }

  function open() {
    setIsVisible(true);
  }
  const DEFAULT_ACTIVE_TAB = "status";
  const { active_tab, id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { isLoading, order } = useSelector((state) => state.restaurantOrder);
  const { order } = useSelector((state) => state.restaurantOrder);

  useEffect(() => {
    if (!active_tab) {
      navigate(`/track/${id}/${DEFAULT_ACTIVE_TAB}`);
    }
  });

  useEffect(() => {
    if (id) {
      dispatch(getARestaurantOrderAction(id));
    }
    // eslint-disable-next-line
  }, [dispatch, id]);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/track/${id}/${tab}`);
    }
  };

  return (
    <div data-test="trackOrderComponent">
      <NavBarCommon />
      <div
        className="min-h-96 bg-cover no-repeat bg-center relative z-0"
        style={{ backgroundImage: `url('${Banner}')` }}
      >
        <Container>
          <div className="py-16">
            <button
              type="button"
              className="text-primary font-bold flex items-center gap-2"
              onClick={() => navigate("/profile/orders")}
            >
              <img src={LEFT} alt="close" className=" w-3 h-3 sm:w-4 sm:h-4" />
              <span>Back to My Orders</span>
            </button>
            <h2
              className="text-5xl font-bold mb-5 mt-10 capitalize"
              style={{ lineHeight: "64px" }}
            >
              {order?.restaurant?.restaurant_name}
            </h2>
            <p className="text-xl">Your order details</p>
          </div>
        </Container>
      </div>

      <Container>
        <main className="py-8">
          <div className="max-w-3xl mx-auto lg:max-w-7xl">
            <h1 className="sr-only">Page title</h1>
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-16">
              {/* Left column */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                <section aria-labelledby="section-1-title">
                  {/* Your content */}
                  <div>
                    <div className="sm:hidden">
                      <label htmlFor="tabs" className="sr-only">
                        Select a tab
                      </label>

                      <select
                        id="tabs"
                        name="tabs"
                        className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base sm:text-sm"
                        onChange={(e) => {
                          toggle(e?.target?.value);
                        }}
                        // defaultValue={tabs.find((tab) => tab.current).name}
                      >
                        {tabs.map((tab) => (
                          <option key={tab.name} value={tab.href}>
                            {tab.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <div className="border-b border-gray-200">
                        <nav
                          className="-mb-px flex space-x-8"
                          aria-label="Tabs"
                        >
                          {tabs.map((tab) => (
                            <button
                              key={tab.name}
                              onClick={() => {
                                toggle(tab.href);
                              }}
                              className={classNames(
                                tab.href === active_tab
                                  ? "border-[#333333] text-[#333333] font-bold"
                                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                              )}
                              aria-current={
                                tab.href === active_tab ? "page" : undefined
                              }
                            >
                              {tab.name}
                            </button>
                          ))}
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className="mt-8">
                    {active_tab === "status" && (
                      <Card.OrderStatus order={order} />
                    )}
                    {active_tab === "details" && (
                      <Card.OrderDetails order={order} />
                    )}
                  </div>
                </section>
              </div>

              {/* Right column */}
              <div className="grid grid-cols-1 gap-4">
                <section aria-labelledby="section-2-title">
                  <h2 className="sr-only" id="section-2-title">
                    Countdown Timer
                  </h2>

                  <div className="p-2 md:p-6">
                    {/* Your content */}
                    <CountdownTimer order={order} />
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div className="mt-20">
            <p className="py-3">Need help with your order?</p>
            <div className="border-b-2 max-w-[636px] mb-8"></div>
            <div className="flex flex-col gap-8">
              <div className="flex gap-4 cursor-pointer " onClick={openModal}>
                <div className="w-[48px] h-[48px] bg-[#FFEAE5] rounded items-center justify-center flex">
                  <QuestionMarkCircleIcon
                    className="h-6 w-6 text-primary"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <h6>Contact Support</h6>
                  <p>If you need help with your order</p>
                </div>
              </div>
              <ContactModal show={isOpen} onClose={closeModal} />

              <div className="flex gap-4 cursor-pointer" onClick={open}>
                <div className="w-[48px] h-[48px] bg-[#FFEAE5] rounded items-center justify-center flex">
                  <InformationCircleIcon
                    className="h-6 w-6 text-primary"
                    aria-hidden="true"
                  />
                </div>
                <div>
                  <h6>Restaurant Details</h6>
                  <p>Opening hours and contact details</p>
                </div>
              </div>
              <RestaurantInfo show={isVisible} onClose={close} />
            </div>
          </div>
        </main>
      </Container>
    </div>
  );
}
