import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../../components/container/container";
import Card from "../../../components/cards/cards";
import Button from "../../../components/buttons/buttons";
import PackageSkeleton from "../../../components/skeleton/package";
// import StoreSkeleton from "../../../components/skeleton/store";
import { ReactComponent as Filter } from "../../../assets/images/icons/filter.svg";
import EmptyState from "../../../assets/images/emptyState/food-package.svg";
import { FilterPackageModal } from "../../../components/modals/filterPackageModal";
import { Beat } from "../../../plugins/spinners.plugin";
import { cleanInput } from "../../../helpers/cleanInput";
import {
  getAllPublicPackagesByFilter,
  getMorePublicPackagesAction,
  filterPublicPackageAction,
} from "../../../redux/publicPackages/publicPackagesActions";
import { getAllCategories } from "../../../redux/productCatergory/productCategoryAction";

export default function FoodPackages() {
  const dispatch = useDispatch();
  const user_city = localStorage.getItem("ogwugo-user-city");

  let [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [filterCount, setFilterCount] = useState(0);

  const { allPackages, loadingMore } = useSelector(
    (state) => state.publicPackages
  );
  const { isLoading } = useSelector((state) => state.publicPackages);
  const { allCategories } = useSelector((state) => state.productCatergory);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getAllCategories());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user_city !== null) {
      dispatch(
        getAllPublicPackagesByFilter(
          { package_type: "food", package_city: user_city },
          page
        )
      );
      dispatch(
        filterPublicPackageAction({
          package_type: "food",
          package_city: user_city,
        })
      );
    } else {
      dispatch(getAllPublicPackagesByFilter({ package_type: "food" }, page));
      dispatch(filterPublicPackageAction({ package_type: "food" }));
    }
    //eslint-disable-next-line
  }, [user_city]);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  async function getRestaurantCategories() {
    let items = allCategories?.data?.filter(
      (item) => item.type === "restaurant"
    );
    setCategories(items);
  }

  useEffect(() => {
    getRestaurantCategories();
    // eslint-disable-next-line
  }, [allCategories]);

  const handleFilter = (data) => {
    cleanInput(data);
    dispatch(filterPublicPackageAction(data));
  };

  const loadMore = async () => {
    let newPage = Number(page) + 1;
    setPage(newPage);
    await dispatch(
      getMorePublicPackagesAction({ package_type: "food" }, newPage)
    );
  };

  return (
    <div className="foodPackage" data-test="FoodPackage">
      <FilterPackageModal
        show={isOpen}
        onClose={closeModal}
        categories={categories}
        onClick={handleFilter}
        setFilterCount={setFilterCount}
      />
      <Container>
        <div className="py-5 mt-12">
          <div className="flex justify-between gap-4 items-center mb-2">
            <h3 className="text-3xl font-bold">Food Packages</h3>
            <div className="flex items-center gap-4">
              <span className="hidden sm:block">
                Sort by: <span className="text-primary">All</span>
              </span>
              <div className="w-[40px] h-[40px] bg-[#FFEAE5] rounded items-center justify-center flex relative">
                <span
                  className={`${
                    filterCount > 0 ? "block" : "hidden"
                  } h-4 w-4 rounded-full bg-black text-white absolute -top-1 -right-2 text-center text-xs`}
                >
                  {filterCount}
                </span>
                <Filter
                  className="cursor-pointer h-6 w-6"
                  onClick={openModal}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="py-5">
          {isLoading && <PackageSkeleton />}
          {!isLoading && (
            <>
              {filterCount === 0 && allPackages?.data?.length > 0 ? (
                <>
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
                    {allPackages?.data?.map(
                      (package_item, i) =>
                        package_item?.package_type === "food" && (
                          <Card.FoodPackage key={i} data={package_item} />
                        )
                    )}
                  </div>
                  {/* {loadingMore && <StoreSkeleton />} */}
                  <div className="mt-24 mb-10 text-center">
                    <p className="mb-4">
                      You’ve viewed{" "}
                      <span className="font-bold">
                        {allPackages?.data?.length}
                      </span>{" "}
                      out of {allPackages?.total} results
                    </p>
                    {allPackages?.data?.length < allPackages?.total && (
                      <Button.Secondary
                        title={loadingMore ? <Beat /> : "Load More"}
                        disabled={loadingMore}
                        className="px-16"
                        onClick={() => loadMore()}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className="flex justify-center py-16">
                  <div className="text-center">
                    <img src={EmptyState} alt="empty" />
                    <p className="my-6">No food packages found</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  );
}
