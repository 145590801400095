import React from "react";
import Logo from "../assets/images/icons/ogwugo-o.svg";

export default function LazyLoader() {
  return (
    <div className="h-[80vh] w-full flex justify-center items-center">
      <img
        src={Logo}
        alt="Logo"
        className="animated-rotate w-[40px] h-[40px]"
      />
    </div>
  );
}
