import React, { useState, useCallback, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import Warning from "../../../../assets/images/icons/warning-circle.svg";
import PaymentModalCart from "../../../../components/modals/payment/paymentModalCart";
import ShareOrderModal from "../../../../components/modals/payment/shareOrderModal";
import { PriceNoticeModal } from "../../../../components/modals/payment/PriceNotice";
import { ServiceAreaNoticeModal } from "../../../../components/modals/payment/ServiceAreaNotice";
import { SignUpModal } from "../../../../components/modals/auth/signUpModal";
import { AddressModal } from "../../../../components/modals/address/addressModal";
import { checkoutCart, showMyCart } from "../../../../redux/cart/cartAction";
import {
  isAuthenticatedUser,
  getUserFromCookies,
} from "../../../../utils/Auth";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Checkout({ myCart, currentAddress }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = getUserFromCookies();
  const isAuth = isAuthenticatedUser();
  const paymentModalRef = useRef(null);
  const shareOrderModalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openNotice, setOpenNotice] = useState(false);
  const [serviceNotice, setServiceNotice] = useState(false);
  const [loadingPfm, setLoadingPfm] = useState(false);
  const data = useMemo(
    () => ({
      user_id: user?.id,
      order_type: "standard",
    }),
    [user]
  );

  const { processing } = useSelector((state) => state.cart);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const handleSubmit = useCallback(async () => {
    if (currentAddress.length > 0) {
      paymentModalRef.current.open();
    }
  }, [currentAddress]);

  const handleSubmitShare = useCallback(async () => {
    if (currentAddress.length > 0) {
      setLoadingPfm(true);
      const response = await dispatch(
        checkoutCart({ ...data, user_id: user?.id, payment_type: "collect" })
      );
      if (response.status === "success") {
        setLoadingPfm(false);
        dispatch(showMyCart());
        shareOrderModalRef.current.open();
        shareOrderModalRef.current.getPfm(response?.data?.pfm);
      } else {
        setLoadingPfm(false);
      }
    }
  }, [currentAddress, data, dispatch, user]);

  console.log("myCart", myCart);
  return (
    <div className="grid grid-cols-1 gap-4 mt-10">
      <section aria-labelledby="section-2-title">
        <h2 className="sr-only" id="section-2-title">
          Section title
        </h2>

        <div className="rounded-xl bg-white overflow-hidden shadow-lg">
          <div className="p-6">
            <h3 className="text-xl font-medium mb-10">Pricing including VAT</h3>

            <div className="mb-5">
              <div className="flex justify-between items-center mb-4">
                <label>Subtotal:</label>
                <span>₦{numberWithCommas(myCart?.sub_amount) ?? 0}</span>
              </div>
              <div className="flex justify-between items-center mb-4">
                <label>Service fee:</label>
                <span>₦{numberWithCommas(myCart?.service_fee) ?? 0}</span>
              </div>
              <div className="flex justify-between items-center mb-4">
                <label>Delivery fee:</label>
                <span>₦{numberWithCommas(myCart?.delivery_fee) ?? 0}</span>
              </div>
              <div className="flex justify-between items-center mb-4">
                <label>Insurance fee:</label>
                <span>₦{numberWithCommas(myCart?.insurance_fee) ?? 0}</span>
              </div>
              <div className="flex justify-between items-center mb-4">
                <label>Discount:</label>
                <span>₦{numberWithCommas(myCart?.discount) ?? 0}</span>
              </div>
              <div className="flex justify-between items-center mb-4">
                <label>Tax fee:</label>
                <span>₦{numberWithCommas(myCart?.tax_fee) ?? 0}</span>
              </div>
              <div className="flex justify-between items-center mb-4">
                <label>Handling fee:</label>
                <span>₦{numberWithCommas(myCart?.handling_fee) ?? 0}</span>
              </div>
              <div className="flex justify-between items-center mb-4">
                <label>Total:</label>
                <span className="font-bold">
                  ₦{numberWithCommas(myCart?.amount) ?? 0}
                </span>
              </div>
            </div>

            {isAuth ? (
              <>
                {(myCart?.products?.length > 0 ||
                  myCart?.packages?.length > 0) &&
                  (currentAddress?.length > 0 ? (
                    <>
                      <Button.Primary
                        disabled={processing}
                        title={processing ? <Beat /> : "Place Order"}
                        className={"w-full h-[48px] px-4 mt-6 mb-2"}
                        onClick={() => handleSubmit()}
                      />

                      <Button.Secondary
                        title={loadingPfm ? <Beat /> : "Pay For Me"}
                        className={"w-full h-[48px] px-4 mt-4 mb-2"}
                        onClick={() => handleSubmitShare()}
                      />
                    </>
                  ) : (
                    <Button.Primary
                      title={"Set address to checkout"}
                      className={"w-full h-[48px] px-4 mt-4"}
                      onClick={() => setOpen(true)}
                    />
                  ))}
              </>
            ) : (
              <div className="text-center mt-8">
                <Button.Primary
                  title={"Login to checkout"}
                  className="px-3 py-4 w-full"
                  onClick={openModal}
                />
                <Button.Secondary
                  title={"Check Out As Guest"}
                  className="px-3 py-4 w-full mt-5"
                  onClick={() => navigate("/cart/guest-checkout")}
                />
              </div>
            )}

            {!isAuth ? (
              <SignUpModal
                show={isOpen}
                onClose={closeModal}
                type={"checkout"}
              />
            ) : (
              currentAddress?.length < 1 && (
                <AddressModal show={open} onClose={() => setOpen(false)} />
              )
            )}
          </div>
        </div>

        {/* Service area notice */}
        <div className="mt-8 px-2 py-2 flex gap-3 items-center justify-center">
          <img
            onClick={() => setServiceNotice(true)}
            src={Warning}
            alt="warning"
            className="w-[18px] h-[18px] cursor-pointer"
          />
          <p className="text-sm text-[#333333] font-bold">
            Incorrect service area will result to additional charges...{" "}
            <span
              className="text-primary cursor-pointer"
              onClick={() => setServiceNotice(true)}
            >
              Learn more
            </span>
          </p>
        </div>

        {/* Pricing change notice */}
        <div className="mt-5 px-2 flex gap-2 items-center">
          <img
            onClick={() => setOpenNotice(true)}
            src={Warning}
            alt="warning"
            className="w-[18px] h-[18px] cursor-pointer"
          />
          <p className="text-sm text-[#333333] font-bold">
            Final prices are subject to change...{" "}
            <span
              className="text-primary cursor-pointer"
              onClick={() => setOpenNotice(true)}
            >
              Learn more
            </span>
          </p>
        </div>
      </section>
      <PaymentModalCart
        ref={paymentModalRef}
        payment_type={"standard"}
        visibility={false}
      />
      <ShareOrderModal
        ref={shareOrderModalRef}
        handleSubmit={handleSubmitShare}
      />

      <PriceNoticeModal open={openNotice} setOpen={setOpenNotice} />
      <ServiceAreaNoticeModal open={serviceNotice} setOpen={setServiceNotice} />
    </div>
  );
}
