import React, { useState } from "react";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../assets/images/icons/close.svg";
import Button from "../buttons/buttons";
import { markAsClosed } from "../../redux/storeOrders/storeOrderAction";
import { useNavigate } from "react-router-dom";

export function CloseOrder({ order }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const order_id = order.id;
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const closeOrder = async () => {
    try {
      const response = await dispatch(markAsClosed({ id: order_id }));
      response && closeModal();
      navigate(`/admin/store-orders/closed/1`);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Button.Primary
        title={"Close Order"}
        className="w-28"
        onClick={openModal}
      />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[415px] sm:w-[600px] bg-white rounded-sm pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg">
                  <div>
                    <div className=" sm:block absolute top-3 right-2 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-md sm:p-3 p-1"
                        onClick={closeModal}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>

                    <div className="text-center sm:mt-4 sm:text-center ">
                      <Dialog.Title
                        as="h3"
                        className=" font-meduim text-black "
                      >
                        Close Order
                      </Dialog.Title>
                      <div className="border-b-2 mt-4"></div>
                    </div>
                    <div className="sm:p-8">
                      <p className="text-lg max-w-[300px] mx-auto text-center">
                        Are you sure you want to close this order made by "
                        {order?.recipient_name}"?
                      </p>

                      <div className="flex gap-8 mt-5 w-full">
                        <Button.Secondary
                          title={"Back"}
                          onClick={closeModal}
                          className="w-full"
                        />
                        <Button.Primary
                          title={"Close"}
                          onClick={closeOrder}
                          className="w-full"
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
