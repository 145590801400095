import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/buttons/buttons";
import Input from "../../../../components/inputs/inputs";
import { Beat } from "../../../../plugins/spinners.plugin";
import { cleanInput } from "../../../../helpers/cleanInput";
import { storeInvite } from "../../../../redux/invite/inviteActions";
import { searchAllStores } from "./../../../../redux/stores/storeActions";
import storeType from "../../../../redux/stores/storeTypes";
import Search from "../../../../assets/images/icons/search.svg";

export default function InviteStoreEmployee() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.invite);
  let { searchStore } = useSelector((state) => state.store);

  const [input, setInput] = useState({
    invitee_email: "",
    job_title: "",
    job_description: "",
    type: "employee",
  });
  const [submitted, setSubmitted] = useState(false);
  const [business_id, setBusinessId] = useState("");
  const [searchValue, setSearchValue] = useState({ search: "" });
  const { invitee_email, job_description, job_title, type } = input;

  function handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: value }));
  }

  const handleSearch = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setSearchValue((searchValue) => ({ ...searchValue, [name]: value }));
    if (value.length >= 3) {
      dispatch(
        searchAllStores({
          search: value,
        })
      );
    } else if (value.length <= 3) {
      searchStore = { data: [] };
      dispatch({ type: storeType["SEARCH_STORES"], payLoad: searchStore });
    }
  };

  function fillStore(result) {
    setBusinessId(result.business_id);
    var mydiv = document.getElementById(result.id);
    if (result.id) {
      mydiv.style.borderColor = "border-[#D14522]";
    }

    setSearchValue((searchValue) => ({
      search: result.store_name,
    }));

    searchStore = { data: [] };
    dispatch({ type: storeType["SEARCH_STORES"], payLoad: searchStore });
  }

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setSubmitted(true);

    if (invitee_email && job_title && type && business_id) {
      cleanInput(input);
      // get return url from location state or default to home page
      const response = await dispatch(storeInvite(input, business_id));
      if (response) {
        setSubmitted(false);
        setSearchValue({ search: "" });
        setInput({
          invitee_email: "",
          job_title: "",
          job_description: "",
          type: "employee",
        });
      }
    }
  };
  return (
    <>
      <div className="w-100 mx-auto">
        <div className="mx-auto bg-white sm:w-[630px] w-full mt-10 sm:mt-0 sm:border px-6 pb-4 sm:border-gray-200">
          <div className="border-b-2 py-4 mb-6">
            <p className=" text-base font-medium text-gray-800 flex justify-center ">
              Invite Admin Employee
            </p>
          </div>
          <form name="form" id="invite_form" onSubmit={handleSubmit}>
            <div>
              <div className="mt-2 mb-3 relative">
                <label className="block text-sm font-medium text-[#151515]">
                  Store Name
                </label>
                <input
                  type={"search"}
                  name={"search"}
                  htmlFor={"store_name"}
                  value={searchValue.search}
                  className={
                    "mt-2 block pl-3 pr-10 w-full text-base bg-white focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                  }
                  placeholder={"Search Store"}
                  onChange={handleSearch}
                />
                <img
                  src={Search}
                  alt="search-icon"
                  className=" fa-search w-3 h-3 absolute top-2 right-4"
                />
                {submitted && !business_id && (
                  <div className="text-primary text-xs ">Store required</div>
                )}
              </div>
              {searchStore &&
                Array.isArray(searchStore) &&
                searchStore.map((result, index) => (
                  <div
                    className="p-3 bg-white mb-2  border"
                    id={result.id}
                    key={index}
                    onClick={() => fillStore(result)}
                  >
                    <div className=" grid-cols-3">
                      <div className="col-span-1">
                        <div className="">
                          <h6 className="text-sm font-meduim">
                            {result.store_name}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div
              className={
                "mt-4" + (submitted && job_description ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Job Descriptions"}
                type="text"
                name="job_description"
                htmlFor={"job_description"}
                onChange={handleChange}
                autoComplete="off"
                placeholder="Enter Job description"
                value={job_description}
              />
              {submitted && job_description === "" && (
                <div className="text-primary text-xs ">
                  Job description is required
                </div>
              )}
            </div>

            <div
              className={
                "mt-4" + (submitted && !job_title ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Job Title*"}
                type="text"
                name="job_title"
                htmlFor={"job_title"}
                autoComplete="off"
                placeholder="Enter Job title"
                onChange={handleChange}
                value={job_title}
              />
              {submitted && job_title === "" && (
                <div className="text-primary text-xs ">
                  Job title is required
                </div>
              )}
            </div>

            <div
              className={
                "mt-4" + (submitted && !invitee_email ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Email*"}
                type="email"
                htmlFor={"invitee_email"}
                name="invitee_email"
                id="invitee_email"
                autoComplete="off"
                placeholder="Enter email address"
                onChange={handleChange}
                value={invitee_email}
              />
              {submitted && invitee_email === "" && (
                <div className="text-primary text-xs ">
                  Invitee email is required
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center mx-auto">
        <Button.Primary
          disabled={loading}
          title={loading ? <Beat color={"#ffffff"} /> : "Send Invite"}
          className={"px-4 rounded-sm sm:mt-10"}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
}
